<template>
  <Dialog v-if="visible" @close="handleClose" :width="'70%'" :height="'70%'">
    <div class="dialog-header">
      <h2 class="dialog-title">
        {{ title || 'Select Your Details' }}
      </h2>
    </div>
    <div>
      <div class="dialog-content" v-if="description">
        <h4 v-html="description"></h4>
      </div>
      <div v-if="loading" class="local-loader-container">
        <div class="inava-loader"></div>
      </div>
      <div v-if="gridSearch">
        <span class="k-textbox k-grid-search k-display-flex">
          <k-input
            :style="{ width: '100%' }"
            :placeholder="'Search'"
            :value="globalSearch"
            v-model="globalSearch"
            :inputPrefix="'prefix'"
            @input="filterGridData"
          >
            <template v-slot:prefix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
        </span>
      </div>
      <div>
        <Grid
          ref="grid-table"
          :style="{ height: '100%', minHeight: '300px', width: '100%' }"
          :data-items="filteredDataSource"
          :resizable="true"
          :reorderable="true"
          :sortable="true"
          :sort="sort"
          @sortchange="sortChangeHandler"
          :filterable="isFilterable"
          :filter="filter"
          @filterchange="filterChange"
          :pageable="gridPageable"
          :skip="skip"
          :take="take"
          :total="totalRecords"
          @pagechange="pageChangeHandler"
          :columns="updatedColumns"
          :expand-field="'expanded'"
          @page-change="handlePageChange"
        >
          <!-- Checkbox column for selection -->
          <template v-slot:checkboxTemplate="{ props }">
            <td class="radio-column">
              <input
                type="radio"
                :checked="isSelected(props.dataItem)"
                @change="handleRadioChange(props.dataItem)"
                class="radio-button"
              />
            </td>
          </template>
          <!-- Hyperlink Column Slot -->
          <template v-slot:hyperlinkTemplate="{ props }">
            <td>
              <span>{{ props.dataItem[props.field] || '' }}</span>
              <b-icon
                v-if="isHyperlinkColumn(props.field, props.dataItem)"
                @click.native="openLinkInNewTab(props.field, props.dataItem)"
                class="view-icon"
                icon="open-in-new"
                size="is-small"
                title="Open link in new tab"
              ></b-icon>
            </td>
          </template>
        </Grid>
      </div>
    </div>

    <!-- for array of grid pop required in exceptional handling dedup -->

    <!-- Dialog Action Buttons -->
    <DialogActionsBar>
      <div class="action-buttons">
        <b-button class="action-button cancel-button" @click="handleClose">
          Close
        </b-button>
        <b-button
          class="action-button confirm-button"
          @click="handleConfirm"
          v-if="selectable"
        >
          Confirm
        </b-button>
      </div>
    </DialogActionsBar>
  </Dialog>
</template>

<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import { Grid, GridColumn } from '@progress/kendo-vue-grid'
import { process } from '@progress/kendo-data-query'
import { Input } from '@progress/kendo-vue-inputs'
import Snackbar from '@/components/Snackbar'

export default {
  name: 'IdentifiersSelectableDialog',
  components: {
    Dialog,
    DialogActionsBar,
    Grid,
    GridColumn,
    'k-input': Input
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    dataItems: Array,
    selectable: {
      type: Boolean,
      default: false
    },
    searchableCols: {
      type: Array,
      default: () => []
    },
    gridSearch: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    title: String,
    selectValue: {
      type: String,
      default: ''
    },
    closeDialog: Function,
    confirm: Function,
    formFieldName: {
      type: String,
      default: ''
    },
    isAllColFilterable: {
      type: Boolean,
      default: false
    },
    hyperlinksCol: {
      type: Array,
      default: () => [] // Array of object: [{column, generator}]
    },
    description: {
      type: String,
      default: ''
    },
    descriptionObj: {
      type: Object,
      default: null
    },
    dataItemsObj: Object
  },
  data() {
    return {
      loading: false,
      gridDataSource: this.dataItems,
      filteredDataSource: [],
      skip: 0,
      take: this.pageSize,
      totalRecords: this.dataItems.length,
      selectedItem: null,
      filter: null,
      sort: [],
      globalSearch: ''
    }
  },
  computed: {
    gridPageable() {
      return this.pagination
        ? {
            buttonCount: 3,
            info: true,
            type: 'numeric',
            previousNext: true
          }
        : null
    },
    updatedColumns() {
      let columns = []
      if (this.dataItems.length > 0) {
        if (this.selectable) {
          columns.push({
            field: 'checkbox',
            title: 'Select',
            width: 75,
            filterable: false,
            sortable: false,
            cell: 'checkboxTemplate'
          })
        }
        columns.push(
          ...Object.keys(this.dataItems[0]).map((key) => {
            const isHyperlink = this.hyperlinksCol.some(
              (link) => link.column === key
            )

            const colData = {
              field: key,
              title: key,
              filterable:
                this.isAllColFilterable || this.searchableCols.includes(key),
              cell: isHyperlink ? 'hyperlinkTemplate' : undefined
            }

            if (isHyperlink) {
              colData.width = 230
            }
            return colData
          })
        )
      }
      return columns
    },
    isFilterable() {
      return this.updatedColumns.some((column) => column.filterable)
    }
  },
  watch: {
    dataItems(newDataItems) {
      this.gridDataSource = newDataItems
      this.totalRecords = newDataItems.length
      this.updateGridData()
    },
    visible(newValue) {
      if (newValue) {
        // Reset the grid data when the dialog is opened
        this.updateGridData()
      }
    }
  },
  methods: {
    handlePageChange(event) {
      this.skip = (event.page - 1) * this.take
      this.take = event.pageSize
    },
    applySearch(searchTerm) {
      if (searchTerm) {
        this.filteredDataSource = this.gridDataSource.filter((item) => {
          return Object.keys(item).some((key) => {
            return item[key]
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          })
        })
      } else {
        this.filteredDataSource = this.gridDataSource
      }
    },
    handleRadioChange(dataItem) {
      this.selectedItem = dataItem
    },
    isSelected(dataItem) {
      return this.selectedItem === dataItem
    },
    filterChange: function (ev) {
      this.filter = ev.filter
      this.updateGridData()
    },
    pageChangeHandler: function (event) {
      this.skip = event.page.skip
      this.take = event.page.take
      this.updateGridData()
    },
    handleClose() {
      this.resetSelection()
      this.closeDialog()
    },
    handleConfirm() {
      if (this.selectable) {
        if (!this.selectedItem) {
          Snackbar({
            message: 'Please select an item.',
            type: 'is-warning',
            indefinite: true
          })
          return
        }
        this.confirm(this.formFieldName, this.selectedItem[this.selectValue])
      }
      this.handleClose()
    },
    updateGridData() {
      const processedData = this.processGridData(this.gridDataSource)
      this.totalRecords = processedData.total
      this.filteredDataSource = processedData
    },

    processGridData(data, skipPagination = false) {
      return process(data, {
        take: this.take,
        skip: this.skip,
        total: data.length,
        filter: this.filter,
        sort: this.sort
      })
    },

    filterGridData(event) {
      this.applySearch(event.target.value)
      this.processGridData(this.filteredDataSource)
    },

    sortChangeHandler: function (event) {
      this.sort = event.sort
      this.updateGridData()
    },

    resetSelection() {
      this.filter = null
      this.sort = []
      this.selectedItem = null
      this.globalSearch = ''
      this.filteredDataSource = []
      this.take = 0
      this.skip = 0
      this.updateGridData()
    },
    isHyperlinkColumn(column, dataItem) {
      // Safely access hyperlinksCol with a default empty array
      return (this.hyperlinksCol || []).some(
        (link) => link.column === column && dataItem[column]
      )
    },
    getHyperlink(column, dataItem) {
      // Safely access hyperlinksCol with a default empty array
      const hyperlinkConfig = (this.hyperlinksCol || []).find(
        (link) => link.column === column
      )
      return hyperlinkConfig ? hyperlinkConfig.generator(dataItem[column]) : ''
    },
    openLinkInNewTab(column, dataItem) {
      const hyperlink = this.getHyperlink(column, dataItem)
      if (hyperlink) {
        window.open(hyperlink, '_blank')
      }
    }
  },
  created() {
    this.filteredDataSource = this.gridDataSource
  }
}
</script>

<style scoped lang="scss">
$primary-color: #3f6ad8;

.local-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  .inava-loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
}

.dialog-header {
  padding: 10px;
  border-radius: 5px 5px 0 0;
  text-align: left;
  .dialog-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    color: $primary-color;
  }
}
.dialog-content {
  padding-left: 10px;
  margin-bottom: 10px;
}

.k-input {
  border: 1px solid #cccccc;

  input {
    // height: 44px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    font-family: Quicksand;
    font-style: normal;
  }

  .k-input-inner {
    margin-left: 0.1rem;
  }

  .k-input-prefix {
    margin-left: 0.5rem;
    color: #cccccc;
  }
}
.k-textbox {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 0.5rem;

  &:focus {
    border-color: $primary-color;
    outline: none;
  }
}

::v-deep .k-table-td {
  // Removed filter icon on grid
  .k-filtercell {
    .k-filtercell-wrapper {
      .k-filtercell-operator {
        display: none !important;
      }
    }
  }
}

.radio-column {
  text-align: center;
  .radio-button {
    height: 17px;
    width: 17px;
  }
}
.k-dialog-wrapper {
  z-index: 999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  .action-button {
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.18px;
    border-radius: 8px;
    padding: 8px;
  }
  .cancel-button {
    background-color: #cccccc;
    opacity: 1;
    color: #444444;
    &:hover {
      background-color: #cccccc;
      opacity: 0.9;
    }
  }
  .confirm-button {
    background-color: #535eeb;
    color: #ffffff;
    opacity: 1;
    margin-left: 1rem;
    &:hover {
      background-color: #00218a;
      opacity: 0.9;
    }
  }
}
.grid-link {
  color: #007bff; /* Custom hyperlink color */
  text-decoration: none; /* Removes underline */
}

.grid-link:hover {
  color: #0056b3; /* Darker color on hover */
  text-decoration: underline; /* Adds underline on hover */
}

.view-icon {
  cursor: pointer;
  color: #535eeb;
}
</style>
