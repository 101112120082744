<template>
  <div class="data-grid-container">
    <Grid
      ref="grid"
      :style="'height: 100%;maxWidth: 100%'"
      class="grid-data-table"
      :data-items="gridData"
      :resizable="true"
      :sortable="true"
      :pageable="gridPageable"
      :sort="sort"
      :filterable="false"
      :take="take"
      :skip="skip"
      :total="total"
      :expand-field="expandField"
      :selectable="false"
      :columns="updatedColumns"
      :column-menu="true"
      :selected-field="selectedField"
      :row-render="cellFunction"
      @headerselectionchange="onHeaderSelectionChange"
      @selectionchange="onSelectionChange"
      @datastatechange="dataStateChange"
    >
      <template v-slot:scoreTemplate="{ props }">
        <td class="k-table-td worktype-column-td" role="gridcell" colspan="1">
          {{ getPercentagedScore(props.dataItem.score) }}
        </td>
      </template>
      <template v-slot:viewEntityTemplate="{ props }">
        <td class="k-table-td worktype-column-td" role="gridcell" colspan="1">
          <div
            class="button-container"
            @click="goToEntities(props.dataItem.sgaId)"
          >
            <p class="button-text">View Entity</p>
          </div>
        </td>
      </template>
      <template v-slot:LENTemplate="{ props }">
        <td class="k-table-td worktype-column-td" role="gridcell" colspan="1">
          <SvgIcon
            v-if="props.dataItem.isMaster"
            :icon="kpiStatusDenyIcon"
            :size="'medium'"
            class="master-icon"
          />
          <!-- Duplicate: If not master but processed -->
          <SvgIcon
            v-if="!props.dataItem.isMaster && props.dataItem.isDuplicate"
            :icon="windowRestoreIcon"
            :size="'medium'"
            class="duplicate-icon"
          />
          {{ props.dataItem.legalEntityName }}
        </td>
      </template>
    </Grid>
  </div>
</template>

<script>
import { process } from '@progress/kendo-data-query'
import { kpiStatusDenyIcon, windowRestoreIcon } from '@progress/kendo-svg-icons'
import { SvgIcon } from '@progress/kendo-vue-common'
import { Grid } from '@progress/kendo-vue-grid'
import { Input } from '@progress/kendo-vue-inputs'
import { mapActions } from 'vuex'
import { exceptionHandlingDuplicatescolumns } from './columns'

export default {
  name: 'ExceptionHandlingDuplicatesTableGrid',
  components: {
    SvgIcon,
    Grid,
    'k-input': Input
  },
  props: {
    dataItems: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      windowRestoreIcon,
      kpiStatusDenyIcon,
      searchWord: '',
      selectedField: 'selected',
      expandField: 'expanded',
      gridPageable: {
        buttonCount: 6,
        info: true,
        type: 'numeric',
        pageSizes: [200],
        previousNext: true
      },
      exceptionHandlingDuplicatescolumns,
      gridData: [],
      skip: 0,
      take: 200,
      total: 0,
      sort: [],
      filter: null,
      customFilter: null,
      exceptionHandlingDataItem: [],
      expandedItems: []
    }
  },
  computed: {
    selectedDataItems() {
      return this.gridData.data.filter((item) => item.selected === true)
    },
    areAllSelected() {
      if (!this.gridData) {
        return false
      }
      return this.gridData.every((item) => item.selected)
    },
    updatedColumns() {
      return this.exceptionHandlingDuplicatescolumns.map((column) => {
        // Update the 'selected' column's headerSelectionValue
        if (column.field === 'selected') {
          return {
            ...column,
            headerSelectionValue: this.areAllSelected
          }
        }
        return column
      })
    }
  },
  watch: {
    dataItems: {
      handler() {
        this.exceptionHandlingDataItem = this.dataItems
        this.updateGridData()
      },
      immediate: true
    }
  },
  mounted() {
    this.applyRowColors()
  },
  methods: {
    // GRID UTILITIES
    ...mapActions('dmp', ['updateEntitiesStatus']),
    applyRowColors() {
      const rows = document.querySelectorAll('.k-grid-table tr')
      rows.forEach((row, index) => {
        const exceptionType = this.gridData[index]?.exceptionType[0]
        if (exceptionType) {
          row.classList.add(this.getExceptionTypeClass(exceptionType))
          row.dataset.exceptionType = exceptionType
        }
      })
    },

    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField]
      const selectedDataItems = this.gridData?.filter(
        (item) => item?.selected === true
      )
      this.$emit('selectionChange', selectedDataItems)
    },

    getExceptionTypeClass(exceptionType) {
      switch (exceptionType) {
        case 'Duplicate':
          return 'work-type-corporate-action'
        case 'Invalid':
          return 'work-type-data-enrichment'
        default:
          return ''
      }
    },

    async navigateToViewEntity(sgaId) {
      // View Entity Redirection
      await this.updateEntitiesStatus({
        entityStatus: false,
        entityDetails: { sgaId }
      })
      this.$router.push({
        name: 'viewEntity'
      })
    },

    processGridData(data, skipPagination = false) {
      return process(data, {
        take: skipPagination ? data.length : this.take,
        skip: skipPagination ? 0 : this.skip,
        sort: this.sort,
        filter: this.filter
      })
    },

    updateGridData(skipPagination = false) {
      let filteredData = this.exceptionHandlingDataItem

      if (this.customFilter) {
        filteredData = this.exceptionHandlingDataItem.filter((item) => {
          return this.customFilter.filters.some((filter) => {
            if (item[filter.field]) {
              return item[filter.field]
                .toString()
                .toLowerCase()
                .includes(filter.value)
            }
            return null
          })
        })
      }

      const processedData = this.processGridData(filteredData, skipPagination)
      this.gridData = processedData.data
      this.total = processedData.total
    },

    // FILTERING
    constructFilter(inputValue, filterFields) {
      const filters = []
      const isValueArray = Array.isArray(inputValue)
      const isFieldArray = Array.isArray(filterFields)

      if (filterFields === 'exceptionType') {
        const inputIds = this.selectedExceptionTypes.map((obj) => obj.id)
        return {
          field: 'exceptionType',
          operator: (item) =>
            item.some((exceptionType) => inputIds.includes(exceptionType))
        }
      }

      const addFilters = (field, value) => {
        filters.push({
          field: field,
          operator: 'contains',
          value: value,
          ignoreCase: true
        })
      }

      if (isValueArray) {
        inputValue.forEach((value) => {
          if (isFieldArray) {
            filterFields.forEach((field) => addFilters(field, value))
          } else {
            addFilters(filterFields, value)
          }
        })
      } else {
        if (isFieldArray) {
          filterFields.forEach((field) => addFilters(field, inputValue))
        } else {
          addFilters(filterFields, inputValue)
        }
      }

      return {
        logic: 'or',
        filters: filters
      }
    },
    dataStateChange(event) {
      this.filter = event.data.filter || this.filter
      this.take = event.data.take
      this.skip = event.data.skip
      this.sort = event.data.sort

      if (
        this.selectedExceptionTypes &&
        this.selectedExceptionTypes.length === 0
      ) {
        this.gridData = []
      } else {
        this.updateGridData()
      }
    },
    onChangeExceptionTypeSelection(value) {
      this.selectedExceptionTypes = value
      this.filter = this.constructFilter(value, 'exceptionType')
      this.skip = 0

      this.updateGridData()
    },
    async goToEntities(sgaId) {
      this.$router.push({
        name: 'viewEntity',
        params: {
          viewSGAId: sgaId
        }
      })
    },

    getRowClass(dataItem) {
      if (
        dataItem.exceptionType &&
        dataItem.exceptionType.includes('Duplicate') &&
        dataItem.exceptionType.length === 1
      ) {
        return 'highlight-row'
      }
      return ''
    },
    cellFunction(h, trElement, defaultSlots, props) {
      const rowClass = this.getRowClass(props.dataItem)
      const existingClass = trElement.data && trElement.data.class
      const combinedClass = `${existingClass} ${rowClass}`
      const trProps = {
        class: combinedClass
      }
      return h('tr', trProps, defaultSlots)
    },
    onHeaderSelectionChange(event) {
      const checked = event.event.target.checked
      const tempData = this.gridData.map((item) => {
        return { ...item, selected: checked }
      })
      this.gridData = tempData
      const selectedDataItems = this.gridData?.filter(
        (item) => item?.selected === true
      )
      this.$emit('selectionChange', selectedDataItems)
    },
    getPercentagedScore(score) {
      return score ? score * 100 + '%' : ''
    }
  },
  destroyed() {
    this.gridData.forEach((item) => {
      item.selected = false
    })
  }
}
</script>

<style lang="scss" scoped>
.data-grid-container {
  margin-left: 27px;
  margin-right: 27px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .grid-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 26px;
    padding-bottom: 26px;
    border: none;

    .grid-toolbar-title {
      p {
        text-align: left;
        font-weight: 600;
        font-style: normal;
        font-size: 28px;
        line-height: 15px;
        font-family: Quicksand;
        letter-spacing: 0px;
        color: #00218a;
        opacity: 1;
        line-height: 1.5rem;
      }
    }
  }

  .work-type-column-container {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    align-items: center;
    flex-direction: row;
    padding-top: 4px;
    padding-bottom: 4px;

    .work-type-content {
      border: 1px solid #444444;
      border-radius: 4px;
      opacity: 1;
      width: 140px;
      height: 30px;
      cursor: pointer;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      font-family: Quicksand;
      letter-spacing: 0.16px;
      color: #444444;
      opacity: 1;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .work-type-corporate-action {
      color: #e10d0d;
      border: 1px solid #e10d0d;
      transition: background-color 0.3s, color 0.3s;
    }

    .work-type-data-enrichment {
      color: #00218a;
      border: 1px solid #00218a;
      transition: background-color 0.3s, color 0.3s;
    }
  }
  .view-icon {
    cursor: pointer;
    color: #535eeb;
  }
}

.duplicate-icon {
  color: #535eeb;
}

.master-icon {
  color: #e10d0d;
}

::v-deep .k-grid-header {
  padding: 0px;
}
::v-deep .k-grid-header-table {
  width: 100% !important ;
}
::v-deep .k-grid-table {
  width: 100% !important ;
}

::v-deep .relationships-evidence-contaier {
  .evidence-button-container {
    .evidence-button {
      height: auto;
      width: auto;
    }
  }
}

::v-deep .k-alt {
  background-color: none;
}

::v-deep .highlight-row {
  background-color: rgb(245, 245, 245);
}

::v-deep .k-column-title {
  font-size: 14px;
  font-family: Quicksand;
  color: #000000;
  letter-spacing: 0.18px;
  white-space: normal;
}

::v-deep .k-table-td {
  font-size: 14px;
  letter-spacing: 0.16px;
  color: #000000;
}

::v-deep .k-table-thead {
  background: #dfdfdf 0% 0% no-repeat padding-box;
}

::v-deep .k-table-header {
  vertical-align: middle;
}

::v-deep .k-grid-table tr {
  transition: background-color 0.3s ease;
}

::v-deep .button-container {
  display: inline-block;
  padding: 5px;
  background-color: #dfdfdf;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 1;
}

::v-deep .button-container:hover {
  background-color: #333333;
  cursor: pointer;
}

::v-deep .button-text {
  margin: 0;
  color: #444444;
}

::v-deep .button-container:hover .button-text {
  color: #ffffff;
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .data-grid-container {
    .grid-toolbar {
      .grid-toolbar-title {
        p {
          font-size: 31px;
          line-height: 23px;
        }
      }

      .grid-toolbar-filters {
        .dropdown-container {
          ::v-deep .data-source-container {
            .top-label {
              font-size: 16px;
            }

            .dropdown-wrap {
              .data-source-header {
                .button-container {
                  .ellipsis {
                    font-size: 16px;
                  }
                }
              }

              .data-source-abs {
                .custom-checkbox {
                  font-size: 16px;
                }
              }
            }
          }
        }

        ::v-deep .k-grid-search {
          .k-input {
            input {
              font-size: 14px;
            }
          }
        }
      }
    }

    .work-type-column-container {
      .work-type-content {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  ::v-deep .k-column-title {
    font-size: 16px;
    font-family: Quicksand;
    color: #000000;
    letter-spacing: 0.18px;
  }

  ::v-deep .k-table-td {
    font-size: 15px;
    letter-spacing: 0.16px;
    color: #000000;
  }
}

@media (min-width: 1700px) {
  .data-grid-container {
    .grid-toolbar {
      .grid-toolbar-title {
        p {
          font-size: 36px;
          line-height: 23px;
        }
      }

      .grid-toolbar-filters {
        .dropdown-container {
          ::v-deep .data-source-container {
            .top-label {
              font-size: 18px;
            }

            .dropdown-wrap {
              .data-source-header {
                .button-container {
                  .ellipsis {
                    font-size: 18px;
                  }
                }
              }

              .data-source-abs {
                .custom-checkbox {
                  font-size: 18px;
                }
              }
            }
          }
        }

        ::v-deep .k-grid-search {
          .k-input {
            input {
              font-size: 16px;
            }
          }
        }
      }
    }

    .work-type-column-container {
      .work-type-content {
        font-size: 16px;
        line-height: 20px;
        font-family: Quicksand;
      }
    }
  }

  ::v-deep .k-column-title {
    font-size: 18px;
    font-family: Quicksand;
    color: #000000;
    letter-spacing: 0.18px;
  }

  ::v-deep .k-table-td {
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #000000;
  }
}
</style>
