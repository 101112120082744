<template>
  <div class="relationships-section">
    <h2 class="section-title">Relationships</h2>
    <div class="mandatory-note">
      <p>
        Text boxes marked as
        <span class="mandatory">*</span> are mandatory. Control in relationship
        is selected, then Ownership % is mandatory.
      </p>
    </div>
    <div class="required-error" v-if="relationshipEvd.errorMessage">
      Please upload evidence for Relationships
    </div>
    <div
      v-for="(error, key) in validationErrors"
      v-if="error"
      class="required-error"
      :key="key"
    >
      {{ error }}
    </div>
    <div class="relationship-form">
      <div class="local-loader-container" v-if="loading">
        <div class="inava-loader"></div>
      </div>
      <div class="column-view odd-grid">
        <div class="search-field">
          <DMPSearchSelect
            label="SGA ID (12 digits)/Request ID/Entity Name"
            field="sgaId"
            :data="sgaIdData"
            :isButtonRequired="true"
            @onChange="onChangeSgaIdOrEntityId"
            :submitted="submitted"
            :isMandatory="true"
            :setValue="setValue"
            @getEntitySGADetails="getEntitySGADetails"
            @addEntityClicked="showAddEntityAddDialog = true"
            :notFoundMessage="'Entity Not Found'"
            :isLoading="isLoadingSgaID"
          ></DMPSearchSelect>
        </div>
      </div>

      <div class="column-view">
        <div class="relationship-column-view">
          <div class="relationship-select-field">
            <SearchableSelect
              :value="selectedRelationshipType"
              label="Relationship"
              :placeholder="'Select Relationship Type'"
              value_field="value"
              text_field="text"
              class="form-input-field searchable-select"
              @onChangeName="onChangeAttributeSelection"
              :required="true"
              :isMandatory="true"
              :submitted="submitted"
              :mandatorySubmit="submitted"
              :data="relationshipsType"
            >
            </SearchableSelect>
          </div>
          <div class="checkbox-container" v-show="isOwnershipMandatory()">
            <label> Majority control/SI</label>
            <div>
              <input
                type="checkbox"
                v-model="isOwnershipOptional"
                @change="changeOwnershpStatus()"
              />
            </div>
          </div>
          <div
            v-show="isOwnershipMandatory() && !isOwnershipOptional"
            class="ownership-container"
          >
            <NumberField
              inputId="ownership"
              label="Ownership %"
              @onChange="onChangeOwnership"
              :isControlSelected="isControlSelected"
              :isMandatory="true"
              :submitted="submitted"
              :value="percentageOwnership"
              ref="numberFieldComponent"
            />
          </div>
        </div>
      </div>

      <div class="column-view">
        <div class="search-field">
          <DMPSearchSelect
            label="Immediate Parent"
            field="immediateParent"
            :data="immediateParentData"
            :isButtonRequired="true"
            :setValue="setValue"
            :isMandatory="true"
            :submitted="submitted"
            @getEntitySGADetails="getEntitySGADetails"
            @onChange="onChangeImmediateParent"
            @addEntityClicked="showAddEntityAddDialog = true"
            :notFoundMessage="'Immediate Parent Not Found'"
            :isLoading="isLoadingImmediateParent"
          >
          </DMPSearchSelect>
        </div>
      </div>
      <div class="column-view">
        <!-- <div class="search-field"> -->
        <DMPSearchSelect
          label="Ultimate Parent"
          field="ultimateParent"
          :data="ultimateParentData"
          :isButtonRequired="true"
          :setValue="setValue"
          :isMandatory="true"
          :submitted="submitted"
          @getEntitySGADetails="getEntitySGADetails"
          :notFoundMessage="'Ultimate Parent Not Found'"
          @addEntityClicked="showAddEntityAddDialog = true"
          @onChange="onChangeUltimateParnt"
          :isLoading="isLoadingUltimateParent"
        ></DMPSearchSelect>
      </div>
      <div class="column-view evidence-container">
        <div class="add-evidence">
          <AddEvidence
            :hasEvidenceButton="true"
            :evidenceFormStatus="getEvidenceFormStatus(relationshipEvd.key)"
            @click.native="toggleEvidence(relationshipEvd.key)"
            :btnLoading="getBtnLoadingStatus(relationshipEvd.key)"
            :isDisabled="isEvidenceBtnDisabled()"
            :formName="'Relationship'"
          />
        </div>
        <div class="add-relation-button">
          <b-button
            @click="addRelation()"
            :disabled="!isValidForm"
            :class="isValidForm ? 'active-button' : 'button'"
            >Add Relation</b-button
          >
        </div>
      </div>
    </div>

    <div class="workflow-container">
      <RelationshipTable
        @deleteRelationship="deleteRelationshipById"
        @toggleEvidenceForm="toggleEvidenceForm"
        :columns="columns"
        :data-items="relationshipData"
        :show-toolbar="false"
        :isSubmittedEntity="isSubmittedEntity"
        :loading="loading"
      />
    </div>

    <div class="action-buttons">
      <ActionButton
        :isSaveButton="false"
        :isBackButton="true"
        @goBack="goBack"
        :isSaveAndNextButton="false"
      >
      </ActionButton>
    </div>
    <template>
      <ConfirmationDialog
        :visible="confirmationDialogData.visible"
        :title="confirmationDialogData.title"
        :bodyText="confirmationDialogData.text"
        :cancelButtonText="confirmationDialogData.cancelButtonText"
        :confirmButtonText="confirmationDialogData.confirmButtonText"
        :closeDialog="closeDialog"
        :triggerFunction="confirmationDialogData.triggerFunction"
        :type="confirmationDialogData.type"
      >
      </ConfirmationDialog>
    </template>
    <template>
      <DMPSelectableMultipleGrid
        v-if="hierarchyDialogInfo.hierarchyRefData?.length > 0"
        :title="hierarchyDialogInfo?.title"
        :description="hierarchyDialogInfo?.description"
        :multiRecordDataSource="hierarchyDialogInfo?.hierarchyRefData"
        :singleRecordDataSource="hierarchyDialogInfo?.currentHierarchyData"
        :visible="hierarchyDialogInfo.visible"
        :highlight="hierarchyDialogInfo.highlight"
        :closeDialog="closeHierarchyDialog"
        :pagination="false"
        :selectable="false"
      />
    </template>
    <template>
      <AddEntityDialog
        v-if="showAddEntityAddDialog"
        :visible="showAddEntityAddDialog"
        :closeDialog="closeEntityAddDialog"
      />
    </template>
  </div>
</template>

<script>
import ActionButton from '@/components/DMP/ActionButton.vue'
import AddEntityDialog from '@/components/DMP/AddEntityDialog.vue'
import AddEvidence from '@/components/DMP/AddEvidence.vue'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import DMPSearchSelect from '@/components/DMP/DMPSearchSelect.vue'
import SearchableSelect from '@/components/DMP/SearchableSelect'
import DMPSelectableMultipleGrid from '@/components/DMP/DMPSelectableMultipleGrid.vue'
import NumberField from '@/components/DMP/NumberField.vue'
import Snackbar from '@/components/Snackbar'
import { activeStepIndexName, relationshipTypeObj } from '@/constant/data.js'
import RelationshipTable from '@/views/DataManagement/Relationships/RelationshipsTable.vue'
import { isEmpty } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'relationshipForm',
  components: {
    RelationshipTable,
    AddEvidence,
    NumberField,
    ActionButton,
    ConfirmationDialog,
    AddEntityDialog,
    DMPSearchSelect,
    DMPSelectableMultipleGrid,
    SearchableSelect
  },
  props: {
    updateStepper: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      enrichmentObj: null,
      sgaId: null,
      submitted: false,
      loading: false,
      parentEntities: [],
      ultimateParentEntities: [],
      openField: false,
      selectedSgaID: null,
      selectedEntityId: '',
      filter: null,
      entityName: null,
      selectedRelationshipType: null,
      dmpSearchText: '',
      showAddEntityAddDialog: false,
      entityId: null,
      parentId: null,
      parentSgaId: null,
      parentLEN: null,
      ultimateParentId: null,
      ultimateParentSgaId: null,
      ultimateParentLEN: null,
      percentageOwnership: null,
      isOwnershipOptional: false,
      isNotValidOwnershipField: false,
      columns: [
        {
          field: 'sgaId',
          title: 'SGA ID',
          sortable: true,
          width: 150,
          cell: 'sgaIdTemplate'
        },
        {
          field: 'requestId',
          title: 'Request ID',
          sortable: true,
          width: 150
        },
        {
          field: 'legalEntityName',
          title: 'Legal Entity Name',
          resizable: true,
          sortable: true,
          className: 'text-center',
          width: 250
        },
        {
          field: 'countryReg',
          title: 'Country Of Registration',
          resizable: true,
          sortable: true,
          width: 190
        },
        {
          field: 'countryDom',
          title: 'Country Of Domicile',
          resizable: true,
          sortable: true,
          width: 170
        },
        {
          field: 'immediateParent',
          title: 'Immediate Parent Name',
          sortable: true,
          width: 200
        },
        {
          field: 'ultimateParent',
          title: 'Ultimate Parent Name',
          sortable: true,
          width: 200
        },
        {
          field: 'relationship',
          title: 'Relationship Type',
          filter: 'numeric',
          sortable: true,
          width: 250
        },
        {
          field: 'ownership',
          title: 'Ownership %',
          sortable: true,
          width: 120
        },
        {
          field: 'Evidence',
          title: 'Evidence',
          width: '150px',
          cell: 'viewEvidenceTemplate',
          sortable: false

          // columnMenu: false
        },
        {
          field: 'Action',
          title: 'Action',
          width: '70px',
          cell: 'actionButtonTemplate',
          sortable: false

          // columnMenu: false
        }
      ],

      confirmationDialogData: {
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {},
        tableData: null,
        type: 'confirm'
      },
      inputNameMapping: {},
      relationshipEvd: {
        key: 'Relationships Evidence',
        errorMessage: false
      },
      setValue: false,
      isControlSelected: false,
      isControlAlreadyAdded: false,
      dropdownsWatcher: {},
      hierarchyDialogInfo: {
        hierarchyRefData: [],
        currentHierarchyData: [],
        title: '',
        description: '',
        visible: false,
        highlight: null
      },
      validationErrors: {
        relationshipType: null,
        relationshipCount: null,
        selfReference: null,
        standalone: null,
        circular: null
      }
    }
  },
  computed: {
    ...mapState('relationship', [
      'relationshipData',
      'relationshipsType',
      'sgaIdData',
      'immediateParentData',
      'ultimateParentData',
      'isLoadingSgaID',
      'isLoadingImmediateParent',
      'isLoadingUltimateParent'
    ]),
    ...mapState('evidence', ['evidenceIdList']),
    ...mapGetters('evidence', ['getEvidenceFormStatus', 'getBtnLoadingStatus']),
    ...mapGetters('dmp', ['getEntityDetails']),
    ...mapState('assignedEntities', ['isSubmittedEntity']),
    isEvdFieldFilled() {
      this.relationshipEvd.errorMessage =
        this.relationshipEvd.errorMessage || this.submitted
          ? !this.evidenceIdList.includes(this.relationshipEvd.key) &&
            !isEmpty(this.selectedEntityId) &&
            !isEmpty(this.selectedRelationshipType)
          : false
      return (
        !isEmpty(this.selectedEntityId) &&
        !isEmpty(this.selectedRelationshipType)
      )
    },
    isValidForm() {
      return this.isEvdFieldFilled && !this.isSubmittedEntity
    }
  },
  watch: {
    evidenceIdList: {
      handler() {
        // When evidence is uploaded then remove evidence message
        this.relationshipEvd.errorMessage =
          this.relationshipEvd.errorMessage || this.submitted
            ? !this.evidenceIdList.includes(this.relationshipEvd.key)
            : false
      },
      deep: true
    },
    isControlSelected() {
      this.percentageOwnership = null
      this.$refs.numberFieldComponent.clearMessage()
    },
    relationshipData() {
      this.isControlAlreadyAdded =
        this.relationshipData &&
        this.relationshipData.length > 0 &&
        this.relationshipData.some((relation) =>
          relation.relationship?.includes(relationshipTypeObj.CONTROL)
        )

      if (this.selectedRelationshipType) {
        // Trigger watcher validation
        this.selectedRelationshipType = {
          ...this.selectedRelationshipType
        }
      }
    },
    dropdownsWatcher: {
      handler() {
        // Reset the error messages
        this.relationshipValidations()
        this.relationshipTypeValidations()
      },
      immediate: true
    },
    selectedRelationshipType() {
      this.relationshipValidations()
      this.relationshipTypeValidations()
    }
  },
  async mounted() {
    this.getFormDetails()
  },

  methods: {
    ...mapActions('relationship', [
      'getRelationships',
      'deleteRelationships',
      'getRelationshipsType',
      'addRelationships',
      'getDataForSgaId',
      'getDataForImmediateParentId',
      'getDataForUltimateParentId',
      'getDataForEntityDropdown',
      'updateDataForSgaID',
      'updateDataForImmediateParent',
      'updateDataForUltimateParent'
    ]),
    ...mapActions('evidence', [
      'uploadEvidence',
      'updateEvidenceIdList',
      'resetEvidenceStore',
      'removeEvidence'
    ]),
    ...mapActions('assignedEntities', ['checkAndUpdateTodoStatus']),
    ...mapActions('relationship', ['updateHierarchyView']),

    // This functionchecking if user proceed without save the changes,for now returning true. need to refactor in upoming sprint once poc completed for basic details
    shouldAllowFormSwitch() {
      return true
    },

    async getFormDetails() {
      try {
        this.resetEvidenceStore()
        this.loading = true
        const { sgaId } = this.getEntityDetails

        this.sgaId = sgaId
        this.getRelationships({
          sgaId: sgaId
        })
        this.getRelationshipsType()
        const entityObj = {
          searchQuery: '',
          field: ''
        }
        this.getDataForEntityDropdown(entityObj)
        this.updateDataForSgaID([])
        this.updateDataForImmediateParent([])
        this.updateDataForUltimateParent([])
      } finally {
        this.loading = false
      }
    },

    changeOwnershpStatus() {
      if (this.isOwnershipOptional) {
        this.percentageOwnership = 999
      } else {
        this.percentageOwnership = null
      }
    },
    async getEntitySGADetails(searchQuery, field) {
      const entityObj = {
        searchQuery: searchQuery,
        field: field
      }
      if (field === 'sgaId') {
        await this.getDataForEntityDropdown(entityObj)
      } else if (field === 'immediateParent') {
        await this.getDataForEntityDropdown(entityObj)
      } else if (field === 'ultimateParent') {
        await this.getDataForEntityDropdown(entityObj)
      }
    },
    onChangeAttributeSelection(data) {
      this.selectedRelationshipType = data

      switch (data?.name) {
        case relationshipTypeObj.CONTROL:
          this.isControlSelected = true
          break
        default:
          this.isControlSelected = false
      }

      this.openField = true
      this.isOwnershipMandatory()
    },
    goBack() {
      this.updateStepper(activeStepIndexName.aedetails)
    },
    onChangeSgaIdOrEntityId(enrichmentObj) {
      if (enrichmentObj) {
        this.enrichmentObj = enrichmentObj
        this.selectedEntityId = enrichmentObj.entityId
        this.selectedSgaID = enrichmentObj.sgaId
        this.entityName = enrichmentObj.text
      } else {
        this.enrichmentObj = null
      }
      this.isOwnershipMandatory()
      this.removeEvidence(this.relationshipEvd.key)
      this.dropdownsWatcher = {
        ...this.dropdownsWatcher,
        selectedEntity: enrichmentObj
      }
    },
    onChangeOwnership(percentageOwnership, isNotValid) {
      this.percentageOwnership = percentageOwnership
      this.isNotValidOwnershipField = isNotValid
      this.removeEvidence(this.relationshipEvd.key)
    },
    onChangeImmediateParent(enrichmentObj) {
      this.parentId = enrichmentObj?.entityId
      this.parentSgaId = enrichmentObj?.sgaId
      this.parentLEN = enrichmentObj?.entityName
      this.removeEvidence(this.relationshipEvd.key)
      this.dropdownsWatcher = {
        ...this.dropdownsWatcher,
        immediateParent: enrichmentObj
      }
    },
    onChangeUltimateParnt(enrichmentObj) {
      this.ultimateParentId = enrichmentObj?.entityId
      this.ultimateParentSgaId = enrichmentObj?.sgaId
      this.ultimateParentLEN = enrichmentObj?.entityName
      this.removeEvidence(this.relationshipEvd.key)
      this.dropdownsWatcher = {
        ...this.dropdownsWatcher,
        ultimateParent: enrichmentObj
      }
    },
    async addRelation() {
      this.submitted = true
      if (
        !this.selectedSgaID ||
        !this.ultimateParentId ||
        !this.parentId ||
        !this.selectedRelationshipType ||
        !this.relationshipTypeValidations() ||
        !this.relationshipValidations()
      ) {
        return
      }
      if (this.isValidForm && !this.isValidEvidence()) {
        return
      }

      const mandatoryCheckPassed = this.isOwnershipMandatory()
      if (
        mandatoryCheckPassed &&
        !this.isOwnershipOptional &&
        (!this.percentageOwnership || this.isNotValidOwnershipField)
      ) {
        return
      }
      const relationshipObj = {
        sgaId: this.sgaId,
        data: {
          entityId: this.selectedEntityId,
          parentId: this.parentId,
          percentageOwnership: this.percentageOwnership,
          ultimateParentId: this.ultimateParentId,
          relationshipEvdIds: null,
          relationshipTypeId: [this.selectedRelationshipType?.id],
          relationshipNo: null
        }
      }

      try {
        this.loading = true
        // Evidence upload & mapping
        const evdIdMappingRes = await this.uploadEvidence()
        const mappedEvdIds = this.updateEvidenceIdMapping(evdIdMappingRes)
        relationshipObj.data.relationshipEvdIds =
          mappedEvdIds[this.relationshipEvd.key]?.length > 0
            ? mappedEvdIds[this.relationshipEvd.key]
            : []

        // Check entity status and update it to In Progress, if it's Todo
        await this.checkAndUpdateTodoStatus()
        const addeRelationshipRes = await this.addRelationships(relationshipObj)
        if (addeRelationshipRes?.status === 200) {
          Snackbar({
            message: 'Relationship data added successfully',
            type: 'is-success'
          })
          await this.resetForm()
          await this.getRelationships({
            sgaId: this.sgaId
          })
        } else if (addeRelationshipRes?.status === 400) {
          const {
            multipleParent,
            circularHierarchy,
            ultimateParentRefs,
            multipleImmediateParent,
            duplicateRelations
          } = addeRelationshipRes?.data

          const hierarchyData = (
            multipleParent ||
            circularHierarchy ||
            ultimateParentRefs ||
            multipleImmediateParent ||
            duplicateRelations ||
            []
          )?.map((item) => {
            return {
              'Enrichment Entity': `${item?.enrichmentSgaId} - ${item?.enrichmentLEN}`,
              'Relationship Type': item?.relationshipTypes?.join(', '),
              'Assigned To': item?.assignedToName || '',
              Entity: `${item?.selfSgaId} - ${item?.selfLEN}`,
              'Immediate Parent': `${item?.parentSgaId} - ${item?.parentLEN}`,
              'Ultimate Parent': `${item?.ultimateParentSgaId} - ${item?.ultimateParentLEN}`
            }
          })

          const { selectedEntity, immediateParent, ultimateParent } =
            this.dropdownsWatcher

          const currentHierarchyData = [
            {
              'Enrichment Entity': `${selectedEntity?.sgaId} - ${selectedEntity?.entityName}`,
              Entity: `${selectedEntity?.sgaId} - ${selectedEntity?.entityName}`,
              'Immediate Parent': `${immediateParent?.sgaId} - ${immediateParent?.entityName}`,
              'Ultimate Parent': `${ultimateParent?.sgaId} - ${ultimateParent?.entityName}`
            }
          ]

          const {
            Entity: currentEntity,
            'Immediate Parent': currentImmediateParent,
            'Ultimate Parent': currentUltimateParent
          } = currentHierarchyData[0]

          let commonHierarchyDialogInfo = {
            hierarchyRefData: hierarchyData,
            currentHierarchyData: currentHierarchyData,
            visible: true
          }

          if (multipleParent) {
            this.hierarchyDialogInfo = {
              ...commonHierarchyDialogInfo,
              title: 'Multiple Ultimate Parent',
              description: `Multiple ultimate parent detected. Please check the hierarchy below.`,
              highlight: {
                fields: ['Entity', 'Immediate Parent'],
                values: [currentImmediateParent],
                columns: ['Ultimate Parent']
              }
            }
          } else if (circularHierarchy) {
            this.hierarchyDialogInfo = {
              ...commonHierarchyDialogInfo,
              title: 'Circular Hierarchy ',
              description: `Circular hierarchy detected. Please check the hierarchy below.`,
              highlight: {
                fields: ['Entity', 'Immediate Parent'],
                values: [currentEntity, currentImmediateParent]
              }
            }
          } else if (ultimateParentRefs) {
            this.hierarchyDialogInfo = {
              ...commonHierarchyDialogInfo,
              title: 'Ultimate Parent Error',
              description: `The selected entity is already referenced as an Ultimate Parent for other entities. <br/> Please reprocess the entity/s.`,
              highlight: {
                columns: ['Ultimate Parent']
              }
            }
          } else if (multipleImmediateParent) {
            this.hierarchyDialogInfo = {
              ...commonHierarchyDialogInfo,
              title: 'Multiple Immediate Parent',
              description: `Multiple immediate parent detected. Please check the hierarchy below.`,
              highlight: {
                columns: ['Immediate Parent']
              }
            }
          } else if (duplicateRelations) {
            this.hierarchyDialogInfo = {
              ...commonHierarchyDialogInfo,
              title: 'Duplicate Relationship',
              description: `Duplicate relationship detected. Please check the below relations.`,
              highlight: {
                columns: ['Immediate Parent']
              }
            }
          }
        } else {
          throw new Error()
        }
      } catch (error) {
        // Handle error
        Snackbar({ message: 'Relationship data not added', type: 'is-danger' })
      } finally {
        this.setValue = false
        this.loading = false
      }
    },

    async resetForm() {
      this.submitted = false
      this.setValue = true
      this.enrichmentObj = null
      this.selectedSgaID = null
      this.selectedEntityId = null
      this.entityName = null
      this.percentageOwnership = null
      this.selectedRelationshipType = null
      this.parentId = null
      this.ultimateParentId = null
      this.resetEvidenceStore()
      this.relationshipEvd.errorMessage = false
      this.isOwnershipOptional = false
      this.resetValidationErrors()
      this.dropdownsWatcher = {}
    },

    isOwnershipMandatory() {
      // Ownership mandatory check
      return (
        this.enrichmentObj &&
        [relationshipTypeObj.CONTROL].includes(
          this.selectedRelationshipType?.name
        )
      )
    },
    async deleteRelationshipById(relationshipId) {
      this.confirmationDialogData.visible = true
      this.confirmationDialogData.type = 'confirm'
      this.confirmationDialogData.title = 'Delete Confirmation'
      this.confirmationDialogData.text =
        'Are you sure you want to delete this relationship?'
      this.confirmationDialogData.triggerFunction = async () => {
        this.loading = true
        try {
          // Check entity status and update it to In Progress, if it's Todo
          await this.checkAndUpdateTodoStatus()
          const deleteRelationshipRes = await this.deleteRelationships(
            relationshipId
          )
          if (deleteRelationshipRes === 200) {
            Snackbar({
              message: 'Relationship data deleted successfully',
              type: 'is-success'
            })
            await this.getRelationships({
              sgaId: this.sgaId
            })
          } else {
            throw new Error()
          }
        } catch (error) {
          Snackbar({
            message: 'Relationship data deletion process failed',
            type: 'is-danger'
          })
        } finally {
          this.loading = false
        }
      }
    },
    closeDialog() {
      this.confirmationDialogData = {
        ...this.confirmationDialogData,
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      }
    },
    closeEntityAddDialog() {
      this.showAddEntityAddDialog = false
    },
    toggleEvidenceForm({ inputName, isViewEvidence, evidenceIds }) {
      this.$emit('toggleEvidenceForm', {
        inputName,
        isViewEvidence,
        evidenceIds: evidenceIds || []
      })
    },
    toggleEvidence(inputName) {
      const isViewEvidence = this.getEvidenceFormStatus(inputName)
      const evidenceIds = this.getEvidenceIds(inputName)
      this.toggleEvidenceForm({ inputName, isViewEvidence, evidenceIds })
    },
    getEvidenceIds(evidenceId) {
      return this.inputNameMapping[`${evidenceId}`]
    },
    updateEvidenceMapping(inputName, evdId) {
      this.updateEvidenceIdList(inputName)
      this.$set(this.inputNameMapping, inputName, evdId)
    },
    isEvidenceBtnDisabled() {
      return !this.isEvdFieldFilled
    },
    updateEvidenceIdMapping(evidenceIdMappingResponse) {
      const evidenceIdMapping = {}
      if (
        evidenceIdMappingResponse &&
        Array.isArray(evidenceIdMappingResponse)
      ) {
        evidenceIdMappingResponse.forEach((item) => {
          if (item.evidenceIds && item.evidenceIds.length > 0) {
            const evidenceIds = item.evidenceIds.map((evd) => evd.id)
            evidenceIdMapping[item.attributeName] = evidenceIds
          }
        })
      }
      return evidenceIdMapping
    },
    isValidEvidence() {
      const isRelationshipEvd =
        this.getEvidenceFormStatus(this.relationshipEvd.key) ||
        this.evidenceIdList.includes(this.relationshipEvd.key)
      this.relationshipEvd.errorMessage = !isRelationshipEvd
      return isRelationshipEvd
    },
    relationshipTypeValidations() {
      // Reset error messages
      this.validationErrors.relationshipType = null
      this.validationErrors.relationshipCount = null

      if (!this.selectedRelationshipType) return false

      const selectedRelationshipTypeName = this.selectedRelationshipType.name
      const { selectedEntity, immediateParent, ultimateParent } =
        this.dropdownsWatcher

      // Check for duplicate relation
      const isDuplicateRelation = this.relationshipData.some((relation) => {
        return (
          relation.relationship?.includes(selectedRelationshipTypeName) &&
          relation.entityId === selectedEntity?.entityId &&
          relation.immediateParentId === immediateParent?.entityId &&
          relation.ultimateParentId === ultimateParent?.entityId
        )
      })

      if (isDuplicateRelation) {
        this.validationErrors.relationshipType = 'Relationship already exists'
        return false
      }

      const isRelationtypeAlreadyAdded =
        this.relationshipData &&
        this.relationshipData.length > 0 &&
        this.relationshipData.some((relation) =>
          relation.relationship?.includes(selectedRelationshipTypeName)
        )
      const isSelfSelectedAsChild =
        selectedEntity && this.sgaId === selectedEntity?.sgaId

      // Step 1: Relation count validation
      // Step 2: Self reference validation
      switch (selectedRelationshipTypeName) {
        case relationshipTypeObj.JOINT_VENTURE:
          const relationshipCount = this.relationshipData.filter((relation) =>
            relation.relationship.includes(relationshipTypeObj.JOINT_VENTURE)
          ).length

          const isNotValidJointVenture = [
            immediateParent?.sgaId,
            ultimateParent?.sgaId
          ].some((sgaId) => sgaId !== this.sgaId)

          if (isRelationtypeAlreadyAdded && relationshipCount >= 3) {
            this.validationErrors.relationshipCount =
              'Joint Venture relationship can be added 3 times'
          } else if (
            (immediateParent || ultimateParent) &&
            isNotValidJointVenture
          ) {
            this.validationErrors.relationshipType =
              'Joint Venture relationship must have self entity as Immediate Parent and Ultimate Parent'
          } else {
            return true
          }
          break
        default:
          if (isRelationtypeAlreadyAdded) {
            this.validationErrors.relationshipCount = `${selectedRelationshipTypeName} type relationship is already added for the selected entity`
          } else if (selectedEntity && !isSelfSelectedAsChild) {
            this.validationErrors.relationshipType =
              'Selected child entity must be same as enrichment/self entity'
          } else {
            return true
          }
      }
      return false
    },
    relationshipValidations() {
      if (!this.submitted) return
      const { selectedEntity, immediateParent, ultimateParent } =
        this.dropdownsWatcher
      let isValidationPassed = true
      const selectedSgaId = selectedEntity?.sgaId
      const immediateParentSgaId = immediateParent?.sgaId
      const ultimateParentSgaId = ultimateParent?.sgaId

      this.resetValidationErrors()

      // Step 1: Self reference validation
      const isSelfReferenced = [
        selectedSgaId,
        immediateParentSgaId,
        ultimateParentSgaId
      ].includes(this.sgaId)

      if (!isSelfReferenced) {
        this.validationErrors.selfReference = `Enrichment/Self Entity (${this.sgaId}) must be part of relationship`
        isValidationPassed = false
      } else {
        this.validationErrors.selfReference = null
      }

      // Step 2: Standalone validation (skip for Joint Venture)
      if (
        ![this.selectedRelationshipType?.name].includes(
          relationshipTypeObj.JOINT_VENTURE
        ) &&
        isValidationPassed &&
        this.sgaId === ultimateParentSgaId
      ) {
        const isStandaloneRelation = [
          selectedSgaId,
          immediateParentSgaId,
          ultimateParentSgaId
        ].every((sgaId) => sgaId === this.sgaId)

        if (!isStandaloneRelation) {
          this.validationErrors.standalone = `${this.sgaId} reference as Ultimate Parent, must have standalone relationship`
          isValidationPassed = false
        } else {
          this.validationErrors.standalone = null
        }
      }

      // Step 3: Circular validation
      if (
        isValidationPassed &&
        selectedSgaId === immediateParentSgaId &&
        selectedSgaId !== ultimateParentSgaId
      ) {
        this.validationErrors.circular = `Selected relationship is circular. Please select a different relationship.`
        isValidationPassed = false
      } else {
        this.validationErrors.circular = null
      }

      return isValidationPassed
    },
    closeHierarchyDialog() {
      this.hierarchyDialogInfo.visible = false
    },
    resetValidationErrors() {
      for (let key in this.validationErrors) {
        if (this.validationErrors.hasOwnProperty(key)) {
          this.validationErrors[key] = null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.relationships-section {
  margin-top: 1rem;
  position: relative;
  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .required-error {
    color: red;
    position: relative;
    font-size: 12px;
    margin-top: 0.3rem;
  }
  .section-title {
    font-size: 21px;
    font-weight: 600;
    color: #00218a;
  }
  .mandatory-note {
    p {
      font-size: 12px;
      font-weight: normal;
      font-family: Quicksand;
      letter-spacing: 0.14px;
    }
    .mandatory {
      color: red;
      font-size: 17px;
    }
  }

  .workflow-container {
    width: 100%;
    margin-top: 25px;
  }
  .relationship-form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0rem 2rem;
    width: 100%;

    .column-view {
      width: 80%;
      margin-top: 2rem;
      .search-field {
        width: 100%;
      }
      .relationship-column-view {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0rem 2rem;

        .relationship-select-field {
          width: 55%;
        }

        .checkbox-container {
          width: 15%;
          height: 23px;

          label {
            margin-bottom: 5px;
            text-align: left;
            font-size: 12px;
            font-family: Quicksand;
            font-weight: 600;
            color: #7d7d7d;
            line-height: inherit;
            opacity: 1;
          }

          input[type='checkbox'] {
            border: 1px solid #e02727;
            height: 16px;
            width: 16px;
            margin-top: 2px;
            margin-right: 2px;
          }
        }
        .ownership-container {
          display: flex;
          flex-direction: row;
          width: 20%;
        }
      }

      ::v-deep .data-source-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .top-label {
          text-align: left;
          font-size: 12px;
          font-family: Quicksand;
          font-weight: 600;
          color: #7d7d7d;
          margin-bottom: 5px;
          line-height: inherit;

          opacity: 1;
        }
        .dropdown-wrap {
          .data-source-header {
            background-color: #ffffff;
            border: 1px solid #dbdbdb;
            span {
              width: 100%;
            }
            .button-container {
              .ellipsis {
                color: #1e1e1e;
                font-size: 13px;
                line-height: 20px;
                font-family: Quicksand;
                font-weight: 600;
                font-style: normal;
                width: 95%;
              }
              .placeholder-color {
                font-size: 13px;
                line-height: 20px;
                font-weight: 400;
                color: #cccccc;
                opacity: 1;
              }
              svg path {
                stroke-width: 1;
                stroke: #1e1e1e;
              }
            }
          }

          .data-source-abs {
            background: #ffffff 0% 0% no-repeat padding-box;
            color: #000000;

            .custom-checkbox {
              p {
                color: #000000;
                font-size: 13px;
              }
            }
            .custom-checkbox:hover {
              background: rgba(196, 200, 248, 0.3) 0% 0% no-repeat padding-box;
            }
          }
        }
      }
    }

    .evidence-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      gap: 0rem 2rem;
      width: 27%;
      min-width: 250px;
      align-items: flex-end;
    }

    .add-evidence {
      width: 40%;
      display: flex;
      /* margin-top: 1.25rem; */
      flex-direction: column;
      justify-content: flex-end;
    }
    .owenership {
      width: 50%;
    }

    .add-relation-button {
      width: 40%;
      display: flex;
      /* margin-top: 1.25rem; */
      flex-direction: column;
      justify-content: flex-end;

      .button {
        background: #00218a;
        opacity: 1;
        text-align: left;
        font-size: 13px;
        height: 36px;
        letter-spacing: 0.18px;
        color: #cccccc;
        opacity: 0.7;
      }
      .active-button {
        background: #00218a;
        opacity: 1;
        text-align: left;
        font-size: 13px;
        letter-spacing: 0.18px;
        color: #cccccc;
        opacity: 1;
      }
    }
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .relationships-section {
    .section-title {
      font-size: 21px;
    }
    .mandatory-note {
      p {
        font-size: 12px;
      }
      .mandatory {
        font-size: 17px;
      }
    }
    .workflow-container {
      margin-top: 35px;
    }
    .relationship-form {
      .column-view {
        ::v-deep .data-source-container {
          .top-label {
            font-size: 14px;
          }

          .dropdown-wrap {
            .data-source-abs {
              .custom-checkbox {
                p {
                  font-size: 14px;
                }
              }
            }

            .data-source-header {
              .button-container {
                .ellipsis {
                  font-size: 14px;
                }
              }
            }
          }
        }

        ::v-deep .form-group {
          width: 100%;

          p {
            font-size: 14px;
          }

          .input {
            font-size: 14px;
          }
        }
      }
      .relationship-column-view {
        gap: 0rem 4rem;

        .checkbox-container {
          label {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (min-width: 1700px) {
  .relationships-section {
    .section-title {
      font-size: 21px;
    }
    .mandatory-note {
      p {
        font-size: 12px;
      }
      .mandatory {
        font-size: 17px;
      }
    }
    .workflow-container {
      margin-top: 35px;
    }

    .relationship-form {
      .column-view {
        ::v-deep .data-source-container {
          .top-label {
            font-size: 16px;
          }

          .dropdown-wrap {
            .data-source-abs {
              .custom-checkbox {
                p {
                  font-size: 16px;
                }
              }
            }

            .data-source-header {
              .button-container {
                .ellipsis {
                  font-size: 16px;
                }
              }
            }
          }

          .relationship-column-view {
            gap: 0rem 4rem;

            .checkbox-container {
              label {
                font-size: 16px;
              }
            }
          }
        }

        ::v-deep .form-group {
          width: 100%;

          p {
            font-size: 16px;
          }

          .input {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
