<template>
  <div class="main-container-outer">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>
    <div class="main-container-inner">
      <div class="left-text">
        <div class="entity-info">
          <p class="info-title">
            {{ sgaId ?? 'N/A' }} - {{ entityDetails?.[0]?.entityName ?? 'N/A' }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="!failedToFetchFlag">
      <div class="main-container-inner2">
        <div class="left-text"></div>
        <div class="right-text">
          <div class="exception-container">
            <button
              class="action-button red-button"
              @click="resetSelectedEntity"
            >
              <span class="button-icon">
                <SvgIcon
                  :icon="arrowRotateCcwIcon"
                  :size="'medium'"
                  class="icon"
                />
              </span>
              <span>Clear</span>
            </button>
            <button
              class="action-button blue-button"
              @click="submitDuplicateProcessed"
            >
              <span class="button-icon">
                <SvgIcon
                  :icon="checkOutlineIcon"
                  :size="'medium'"
                  class="icon"
                />
              </span>
              <span>Submit</span>
            </button>
            <div class="placeholder-text">Exception Type:</div>
            <button class="duplicate-button">Duplicate</button>
          </div>
        </div>
      </div>
      <div class="main-container-inner3">
        <div class="left-text">
          <p class="sub-text">Listed below entries are system generated</p>
        </div>
        <div class="right-text">
          <div class="exception-container">
            <button class="action-button red-button" @click="markAsMaster">
              <span class="button-icon">
                <SvgIcon
                  :icon="kpiStatusDenyIcon"
                  :size="'medium'"
                  class="icon"
                />
              </span>
              <span>{{ buttonAction.MASTER }}</span>
            </button>
            <button class="action-button blue-button" @click="markAsDuplicate">
              <span class="button-icon">
                <SvgIcon
                  :icon="windowRestoreIcon"
                  :size="'medium'"
                  class="icon"
                />
              </span>
              <span>{{ buttonAction.DUPLICATE }}</span>
            </button>
            <button class="action-button red-button" @click="removeDuplicate">
              <span class="button-icon">
                <SvgIcon
                  :icon="cancelOutlineIcon"
                  :size="'medium'"
                  class="icon"
                />
              </span>
              <span>{{ buttonAction.NOT_DUPLICATE }}</span>
            </button>
          </div>
        </div>
      </div>
      <ExceptionHandlingDuplicatesTableGrid
        @selectionChange="handleSelectionChange"
        :data-items="dataItems"
      />
    </div>
    <div v-if="failedToFetchFlag" class="text-msg">
      Failed to fetch data, Please try again.
    </div>
    <ConfirmationDialog
      :type="dialog.type"
      :visible="dialog.visible"
      :title="dialog.title"
      :bodyText="dialog.text"
      :cancelButtonText="dialog.cancelButtonText"
      :confirmButtonText="dialog.confirmButtonText"
      :closeDialog="closeDialog"
      :triggerFunction="dialog.triggerFunction"
    />
    <template>
      <DMPSelectableMultipleGrid
        v-if="hierarchyDialogInfo.multipleSgaIdList.length > 0"
        :title="hierarchyDialogInfo?.title"
        :descriptionObj="hierarchyDialogInfo?.descriptionObj"
        :dataItemsObj="hierarchyDialogInfo?.data"
        :visible="hierarchyDialogInfo.visible"
        :closeDialog="closeHierarchyDialog"
        :multipleTableObj="hierarchyDialogInfo?.multipleTableObj"
        :multipleTitleObj="hierarchyDialogInfo?.multipleTitleObj"
        :multipleSgaIdList="hierarchyDialogInfo?.multipleSgaIdList"
        :highlight="hierarchyDialogInfo?.highlight"
        :exceptionHandling="true"
      />
    </template>
  </div>
</template>

<script>
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import DMPSelectableMultipleGrid from '@/components/DMP/DMPSelectableMultipleGrid.vue'
import Snackbar from '@/components/Snackbar'
import Audit from '@/views/DataManagement/Audit/Audit.vue'
import EntityDetails from '@/views/DataManagement/EntityDetails.vue'
import {
  arrowRotateCcwIcon,
  cancelOutlineIcon,
  checkOutlineIcon,
  kpiStatusDenyIcon,
  windowRestoreIcon
} from '@progress/kendo-svg-icons'
import { SvgIcon } from '@progress/kendo-vue-common'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import ExceptionHandlingDuplicatesTableGrid from '@/views/DataManagement/WorkFlowManagement/ExceptionHandling/DuplicateGrid/DuplicatesGrid.vue'

export default {
  name: 'ExceptionHandlingDuplicates',
  components: {
    SvgIcon,
    EntityDetails,
    Audit,
    ConfirmationDialog,
    ExceptionHandlingDuplicatesTableGrid,
    DMPSelectableMultipleGrid
  },
  data() {
    return {
      windowRestoreIcon,
      kpiStatusDenyIcon,
      checkOutlineIcon,
      arrowRotateCcwIcon,
      cancelOutlineIcon,
      dataItems: [],
      isEnabled: false,
      sgaId: '',
      selected: 0,
      isReviewSubmit: false,
      loading: false,
      selectedDataItems: [],
      // confirmation popup
      dialog: {
        type: 'confirm',
        visible: false,
        title: '',
        text: '',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Ok',
        triggerFunction: () => {}
      },
      buttonAction: {
        MASTER: 'Mark as Master',
        DUPLICATE: 'Mark as Duplicate',
        NOT_DUPLICATE: 'Remove Duplicate'
      },
      hierarchyDialogInfo: {
        data: {},
        title: '',
        description: '',
        descriptionObj: '',
        visible: false,
        multipleTableObj: {},
        multipleTitleObj: {},
        multipleSgaIdList: [],
        highlight: null
      }
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('assignedEntities', ['entityDetails']),
    ...mapState('exceptionHandling', [
      'exceptionHandlingDuplicates',
      'multipleUPData',
      'multipleIpData',
      'circularHierarchyData',
      'upErrorRefData',
      'failedToFetchFlag',
      'dupEntitiesData'
    ]),
    ...mapGetters('dmp', ['getEntityDetails'])
  },
  watch: {
    exceptionHandlingDuplicates: {
      handler() {
        this.dataItems = this.exceptionHandlingDuplicates
      },
      immediate: true
    }
  },
  async created() {
    this.loading = true
    const { sgaId } = this.getEntityDetails
    this.sgaId = sgaId
    if (!this.sgaId) {
      // If sgaID is not available then redirect to landing page
      this.$router.push({
        name: 'exception-handling'
      })
    }
    await this.getEntityDetailsById({ sgaId: this.sgaId })
    this.loading = false
  },
  methods: {
    ...mapActions('assignedEntities', ['getEntityDetailsById']),
    ...mapActions('review', ['updateEntityStatus']),
    ...mapActions('exceptionHandling', [
      'getExceptionHandlingDuplicates',
      'updateMasterEntity',
      'updateDuplicateEntity',
      'processAllDuplicates',
      'resetProcessedEntity',
      'removeDuplicateEntity',
      'multipleParentValidation',
      'multipleIpValidation',
      'circularHierarchyValidation',
      'replaceHierarchyWithMaster',
      'ultimateParentValidation',
      'refreshMVForException',
      'relationshipTypeErrorValidaton'
    ]),

    backToForm(isEdit) {
      this.isReviewSubmit = isEdit
    },

    handleSelectionChange(selectedDataItems) {
      this.selectedDataItems = selectedDataItems
    },

    getLastUpdatedDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },

    closeDialog() {
      this.dialog.visible = false
    },

    fetchDuplicateGridData() {
      this.selectedDataItems = []
      return this.getExceptionHandlingDuplicates(this.sgaId)
    },

    async markAsMaster() {
      const selectedCount = this.selectedDataItems.length
      if (selectedCount === 0) {
        this.showSelectOneWarning(this.buttonAction.MASTER)
      } else if (selectedCount > 1) {
        this.showWarningDialog()
        this.dialog.text = 'Only one entity can be marked as Master.'
        this.dialog.triggerFunction = () => {
          this.dialog.visible = false
        }
      } else {
        const selectedEntity = this.selectedDataItems.find(
          (entity) => entity.selected === true
        )

        const masterEntityDetails = this.dataItems.find(
          (entity) => entity.isMaster
        )

        const duplicateEntityDetails = this.dataItems.filter(
          (entity) => entity.isDuplicate && !entity.isMaster
        )

        let isRelationValidationCheckPassed = false

        // Self validation & Duplicate marked validation
        isRelationValidationCheckPassed =
          await this.relationshipValidationChecks(selectedEntity, [
            selectedEntity.sgaId,
            ...duplicateEntityDetails.map((entity) => entity.sgaId)
          ])

        // Mark Entity As Master
        if (isRelationValidationCheckPassed) {
          const entitySummary = `<b>Are you sure you want to mark below entity as master?
          </b><br/><br/>
          <div style="font-size: 13px; text-align: left;"><li>${selectedEntity.sgaId} - ${selectedEntity.legalEntityName}</li></div>`

          try {
            this.showConfirmDialog()
            this.dialog.text = entitySummary
            this.dialog.triggerFunction = async () => {
              this.loading = true
              this.dialog.visible = false
              const updateMasterResult = await this.updateMasterEntity({
                masterSGAID: selectedEntity.sgaId,
                duplicateBaseSGAID: this.sgaId
              })

              if (updateMasterResult) {
                await this.fetchDuplicateGridData()
              }

              Snackbar({
                message: 'Entity marked as Master successfully.',
                type: 'is-success'
              })
              this.loading = false
            }
          } catch (error) {
            // Handle Error Part
            this.errorSnackbar()
          } finally {
            this.loading = false
          }
        }
      }
    },

    async markAsDuplicate() {
      if (this.selectedDataItems.length === 0) {
        this.showSelectOneWarning(this.buttonAction.DUPLICATE)
      } else if (
        !this.dataItems.map((entity) => entity.isMaster).includes(true)
      ) {
        this.showWarningDialog()
        this.dialog.text =
          'Please mark an entity as Master before marking duplicates.'
        this.dialog.triggerFunction = () => {
          this.dialog.visible = false
        }
      } else if (
        this.selectedDataItems.some((entity) => entity.isMaster === true)
      ) {
        this.showWarningDialog()
        this.dialog.text = 'The Master entity cannot be marked as Duplicate.'
        this.dialog.triggerFunction = () => {
          this.dialog.visible = false
        }
      } else {
        const selectedEntities = this.selectedDataItems.filter(
          (entity) => entity.selected === true
        )

        const selectedEntitiesSgaIds = selectedEntities.map(
          (entity) => entity.sgaId
        )
        const masterEntityDetails = this.dataItems.find(
          (entity) => entity.isMaster
        )

        const isRelationValidationCheckPassed =
          await this.relationshipValidationChecks(
            masterEntityDetails,
            selectedEntitiesSgaIds
          )

        if (isRelationValidationCheckPassed) {
          // Mark Entities As Duplicate
          const duplicateEntityList = selectedEntities.map((entity) => {
            return `<li>${entity.sgaId} - ${entity.legalEntityName}</li>`
          })

          const entitySummary = `<b>Are you sure, you want to mark below entities as duplicate?</b><br/><br/>
          <div style="font-size: 13px; text-align: left;">${duplicateEntityList.join(
            ''
          )}<div>`

          try {
            this.showConfirmDialog()
            this.dialog.text = entitySummary
            this.dialog.triggerFunction = async () => {
              this.loading = true
              this.dialog.visible = false
              const updateDuplicateResult = await this.updateDuplicateEntity({
                duplicateSGAIDs: selectedEntities.map((entity) => entity.sgaId),
                duplicateBaseSGAID: this.sgaId
              })

              if (updateDuplicateResult) {
                await this.fetchDuplicateGridData()
              }

              Snackbar({
                message: 'Entities marked as Duplicate successfully.',
                type: 'is-success'
              })
              this.loading = false
            }
          } catch (error) {
            // Handle Error Part
            this.errorSnackbar()
          } finally {
            this.loading = false
          }
        }
      }
    },

    async removeDuplicate() {
      if (this.selectedDataItems.length === 0) {
        this.showSelectOneWarning(this.buttonAction.NOT_DUPLICATE)
      } else if (
        // If master or duplicate entities are selected
        this.selectedDataItems.some(
          (entity) => entity.isMaster || entity.isDuplicate
        )
      ) {
        this.showWarningDialog()
        this.dialog.text = `Entities marked as duplicate and master cannot be <b>Removed</b>.
          <br/>
          Please clear entities marked as duplicate or master first.`
        this.dialog.triggerFunction = () => {
          this.dialog.visible = false
        }
      } else {
        // Mark Entities As Duplicate
        const selectedEntities = this.selectedDataItems.filter(
          (entity) => entity.selected === true
        )
        const duplicateEntityList = selectedEntities.map((entity) => {
          return `<li>${entity.sgaId} - ${entity.legalEntityName}</li>`
        })

        const entitySummary = `<b>Are you sure, you want to remove below entities from duplicate?</b>
        <div style="color: red; font-size: 10px; text-align: left;"><i>(This action cannot be undone)</i></div>
        <br/>
        <div style="font-size: 13px; text-align: left;">${duplicateEntityList.join(
          ''
        )}<div>
        `

        try {
          this.showConfirmDialog()
          this.dialog.text = entitySummary
          this.dialog.triggerFunction = async () => {
            this.loading = true
            this.dialog.visible = false
            const updateDuplicateResult = await this.removeDuplicateEntity({
              notDupEntityIds: selectedEntities.map(
                (entity) => entity.entityId
              ),
              sgaId: this.sgaId
            })

            if (updateDuplicateResult) {
              await this.fetchDuplicateGridData()
            }
            this.loading = false
          }
        } catch (error) {
          // Handle Error Part
        } finally {
          this.loading = false
        }
      }
    },

    async submitDuplicateProcessed() {
      if (
        !this.dataItems.map((entity) => entity.isMaster).includes(true) &&
        !this.dataItems.every((entity) => entity.isDuplicate)
      ) {
        // both master and duplicates are not selected.
        this.showWarningDialog()
        this.dialog.text =
          'All entities must be marked as either Master or Duplicate before submission.'
        this.dialog.triggerFunction = () => {
          this.dialog.visible = false
        }
      } else if (
        !this.dataItems.every((entity) => entity.isMaster || entity.isDuplicate)
      ) {
        // master selected but duplicates are not selected.
        this.showWarningDialog()
        this.dialog.text =
          'All entities must be marked as either Master or Duplicate before submission.'
        this.dialog.triggerFunction = () => {
          this.dialog.visible = false
        }
      } else {
        try {
          // Current Entity Summary
          let entitySummary = `<div style="text-align: left; line-height: 1.6;">
            <b>Are you sure, you want to proceed with below details?</b><br/>
            <b>All Duplicates will be replaced with the Master entity.</b><br/><br/>
            <ul>
              <li><b>Current Entity</b></li>
                <ul style="list-style-type:disc; font-size: 13px;">
                  <li> ${this.sgaId} - ${this.entityDetails?.[0]?.entityName}</li>
                </ul>
            `
          // Master Entity Summary
          const masterEntity = this.dataItems.find((entity) => entity.isMaster)
          const masterSGAID = masterEntity ? masterEntity.sgaId : this.sgaId
          const masterLEN = masterEntity
            ? masterEntity.legalEntityName
            : this.entityDetails?.[0]?.entityName
          entitySummary += `<br/>
            <li><b>Master Entity</b></li>
            <ul style="list-style-type:disc; font-size: 13px;">
              <li> ${masterSGAID} - ${masterLEN}</li>
            </ul>
            `

          // Duplicate Entities Summary
          const duplicateEntity = this.dataItems.filter(
            (entity) => entity.isDuplicate && !entity.isMaster
          )
          const slaveSgaIds = duplicateEntity.map((entity) => entity.sgaId)
          const duplicateEntityList = duplicateEntity.map((entity) => {
            return `<li> ${entity.sgaId} - ${entity.legalEntityName}</li>`
          })
          entitySummary += `<br/>
              <li><b>Duplicate Entity</b></li>
                <ul style="list-style-type:disc; font-size: 13px;">
                  ${duplicateEntityList.join('')}
                </ul>
              </ul>
            </div>
            `

          this.showConfirmDialog()
          this.dialog.text = entitySummary
          this.dialog.triggerFunction = async () => {
            this.loading = true
            this.dialog.visible = false
            const isReplacedSuccess = await this.replaceHierarchyWithMaster({
              masterSgaId: masterSGAID,
              slaveSgaIds,
              userId: this.userDetails.userId
            })

            const updateDuplicateResult = await this.processAllDuplicates(
              this.sgaId
            )

            if (updateDuplicateResult && isReplacedSuccess) {
              Snackbar({
                message: 'Duplicates processed successfully.',
                type: 'is-success'
              })
              await this.refreshMVForException()
              this.$router.push({
                name: 'exception-handling'
              })
            } else {
              // Whole process not executed
              throw new Error()
            }
            this.loading = false
          }
        } catch (error) {
          // Handle Error Part
          this.errorSnackbar()
        } finally {
          this.loading = false
        }
      }
    },

    async resetSelectedEntity() {
      if (this.selectedDataItems.length === 0) {
        this.showWarningDialog()
        this.dialog.text = 'Please select at least 1 entity to reset'
        this.dialog.triggerFunction = () => {
          this.dialog.visible = false
        }
      } else {
        // Mark Entities As Duplicate
        const selectedEntities = this.selectedDataItems.filter(
          (entity) => entity.selected === true
        )
        const selectedEntityList = selectedEntities.map((entity) => {
          return `<li>${entity.sgaId} - ${entity.legalEntityName}</li>`
        })

        const entitySummary = `<b>Are you sure, you want to reset below entities?</b><br/><br/>
        <div style="font-size: 13px; text-align: left;">${selectedEntityList.join(
          ''
        )}<div>`

        try {
          this.showConfirmDialog()
          this.dialog.text = entitySummary
          this.dialog.triggerFunction = async () => {
            this.loading = true
            this.dialog.visible = false
            const resetEntityResult = await this.resetProcessedEntity({
              selectedSGAIDs: selectedEntities.map((entity) => entity.sgaId),
              duplicateBaseSGAID: this.sgaId
            })

            if (resetEntityResult) {
              await this.fetchDuplicateGridData()
            }
            Snackbar({
              message: 'Entity cleared successfully',
              type: 'is-success'
            })
            this.loading = false
          }
        } catch (error) {
          // Handle Error Part
          this.errorSnackbar()
        } finally {
          this.loading = false
        }
      }
    },

    showWarningDialog() {
      this.dialog.type = 'warning'
      this.dialog.visible = true
      this.dialog.title = 'Warning'
      this.dialog.cancelButtonText = 'Cancel'
      this.dialog.confirmButtonText = 'Ok'
    },

    showConfirmDialog() {
      this.dialog.type = 'confirm'
      this.dialog.visible = true
      this.dialog.title = 'Confirm'
      this.dialog.cancelButtonText = 'No'
      this.dialog.confirmButtonText = 'Yes'
    },

    showSelectOneWarning(action) {
      this.showWarningDialog()
      this.dialog.text = `Please select at least 1 entity to <b>${action}</b>`
      this.dialog.triggerFunction = () => {
        this.dialog.visible = false
      }
    },

    closeHierarchyDialog() {
      this.hierarchyDialogInfo.visible = false
    },

    async relationshipValidationChecks(masterEntityDetails, duplicateSGAIDs) {
      const highlightEntities = [
        `${masterEntityDetails.sgaId} - ${masterEntityDetails.legalEntityName}`
      ]

      let isMasterOnly = false
      if (duplicateSGAIDs && duplicateSGAIDs.length == 1) {
        // Check self as slave
        isMasterOnly = masterEntityDetails?.sgaId === duplicateSGAIDs[0]
      }

      // ultimate parent reference validation
      this.loading = true
      const isUPReferenceValidationPassed = await this.ultimateParentValidation(
        {
          masterSgaId: masterEntityDetails.sgaId,
          slaveSgaIds: duplicateSGAIDs
        }
      )
      this.loading = false
      if (!isUPReferenceValidationPassed) {
        const hierarchyData = Object.fromEntries(
          Object.entries(this.upErrorRefData.ultimateParentRefs)?.map(
            ([key, values]) => [key, this.mapRefRelationshipFields(values)]
          )
        )

        let descriptionObj = Object.keys(
          this.upErrorRefData.ultimateParentRefs
        ).reduce((acc, sgaId) => {
          let description = `Ultimate Parent error detected.<br/>`
          description += isMasterOnly
            ? ''
            : `While replacing
            <span style='color: #3f6ad8; font-weight: bold;'>${sgaId}</span>
            with
            <span style='color: #3f6ad8; font-weight: bold;'>${masterEntityDetails.sgaId} - ${masterEntityDetails.legalEntityName}</span>.` +
              `<br/>The entity <span style='color: #3f6ad8; font-weight: bold;'>${masterEntityDetails.sgaId} - ${masterEntityDetails.legalEntityName}</span> is already referenced as an Ultimate Parent for other entities. Please reprocess the entity/s.`

          acc[sgaId] = description
          highlightEntities.push(sgaId)
          return acc
        }, {})

        let multipleSgaIdList = Object.keys(
          this.upErrorRefData.ultimateParentRefs
        )

        this.hierarchyDialogInfo = {
          title: 'Ultimate Parent Error',
          multipleTableObj: hierarchyData,
          multipleTitleObj: descriptionObj,
          multipleSgaIdList: multipleSgaIdList,
          visible: true,
          highlight: {
            fields: ['Entity', 'Immediate Parent', 'Ultimate Parent'],
            values: highlightEntities
          }
        }
      }

      // step 1 multiparent validation
      this.loading = true
      const isMultipleParentValidationPassed = isUPReferenceValidationPassed
        ? await this.multipleParentValidation({
            masterSgaId: masterEntityDetails.sgaId,
            slaveSgaIds: duplicateSGAIDs
          })
        : false
      this.loading = false
      if (isUPReferenceValidationPassed && !isMultipleParentValidationPassed) {
        if (!isEmpty(this.multipleUPData)) {
          const hierarchyData = Object.fromEntries(
            Object.entries(this.multipleUPData.multipleParent)?.map(
              ([key, values]) => [key, this.mapRefRelationshipFields(values)]
            )
          )

          let descriptionObj = Object.keys(
            this.multipleUPData.multipleParent
          ).reduce((acc, sgaId) => {
            let description = `Multiple ultimate parent detected.<br/>`
            description += isMasterOnly
              ? ''
              : `While replacing
              <span style='color: #3f6ad8; font-weight: bold;'>${sgaId}</span>
              with
              <span style='color: #3f6ad8; font-weight: bold;'>${masterEntityDetails.sgaId} - ${masterEntityDetails.legalEntityName}</span>.`

            acc[sgaId] = description
            return acc
          }, {})

          let multipleSgaIdList = Object.keys(
            this.multipleUPData.multipleParent
          )

          this.hierarchyDialogInfo = {
            title: 'Multiple ultimate parent',
            multipleTableObj: hierarchyData,
            multipleTitleObj: descriptionObj,
            multipleSgaIdList: multipleSgaIdList,
            visible: true,
            highlight: {
              fields: ['Entity', 'Immediate Parent'],
              values: highlightEntities,
              columns: ['Ultimate Parent']
            }
          }
        }
      }

      // step 2 circular hierarchy validation
      this.loading = true
      const isCircularHierarchyValidationPassed =
        isMultipleParentValidationPassed
          ? await this.circularHierarchyValidation({
              masterSgaId: masterEntityDetails.sgaId,
              slaveSgaIds: duplicateSGAIDs
            })
          : false
      this.loading = false
      if (
        isMultipleParentValidationPassed &&
        !isCircularHierarchyValidationPassed
      ) {
        if (!isEmpty(this.circularHierarchyData)) {
          const hierarchyData = Object.fromEntries(
            Object.entries(this.circularHierarchyData.circularHierarchy)?.map(
              ([key, values]) => [key, this.mapRefRelationshipFields(values)]
            )
          )

          let descriptionObj = Object.keys(
            this.circularHierarchyData.circularHierarchy
          ).reduce((acc, sgaId) => {
            let description = `Circular hierarchy detected.<br/>`
            description += isMasterOnly
              ? ''
              : `While replacing
              <span style='color: #3f6ad8; font-weight: bold;'>${sgaId}</span>
              with
              <span style='color: #3f6ad8; font-weight: bold;'>${masterEntityDetails.sgaId} - ${masterEntityDetails.legalEntityName}</span>.`

            acc[sgaId] = description
            highlightEntities.push(sgaId)
            return acc
          }, {})

          let multipleSgaIdList = Object.keys(
            this.circularHierarchyData.circularHierarchy
          )

          this.hierarchyDialogInfo = {
            title: 'Circular Hierarchy',
            multipleTableObj: hierarchyData,
            multipleTitleObj: descriptionObj,
            multipleSgaIdList: multipleSgaIdList,
            visible: true,
            highlight: {
              fields: ['Entity', 'Immediate Parent'],
              values: highlightEntities
            }
          }
        }
      }

      // step 3 multiple immediate parent validation
      this.loading = true
      const isMultipleIPValidationPassed = isCircularHierarchyValidationPassed
        ? await this.multipleIpValidation({
            masterSgaId: masterEntityDetails.sgaId,
            slaveSgaIds: duplicateSGAIDs
          })
        : false
      this.loading = false
      if (
        isCircularHierarchyValidationPassed &&
        !isMultipleIPValidationPassed
      ) {
        const hierarchyData = Object.fromEntries(
          Object.entries(this.multipleIpData.multipleParent)?.map(
            ([key, values]) => [key, this.mapRefRelationshipFields(values)]
          )
        )

        let descriptionObj = Object.keys(
          this.multipleIpData.multipleParent
        ).reduce((acc, sgaId) => {
          acc[sgaId] = `Multiple immediate parent detected.<br/>
          While replacing
          <span style='color: #3f6ad8; font-weight: bold;'>${sgaId}</span>
          with
          <span style='color: #3f6ad8; font-weight: bold;'>${masterEntityDetails.sgaId} - ${masterEntityDetails.legalEntityName}</span>.`

          highlightEntities.push(sgaId)
          return acc
        }, {})

        let multipleSgaIdList = Object.keys(this.multipleIpData.multipleParent)

        this.hierarchyDialogInfo = {
          title: 'Multiple immediate parent',
          multipleTableObj: hierarchyData,
          multipleTitleObj: descriptionObj,
          multipleSgaIdList: multipleSgaIdList,
          visible: true,
          highlight: {
            fields: ['Entity', 'Immediate Parent'],
            values: highlightEntities,
            columns: ['Immediate Parent']
          }
        }
      }

      // Relationship type error validation
      this.loading = true
      // below already marked duplicate entity is used to find multiple relationship type error
      const duplicateEntityDetails = this.dataItems.filter(
        (entity) => entity.isDuplicate && !entity.isMaster
      )
      const markedDuplicateSgaIds = duplicateEntityDetails.map(
        (entity) => entity.sgaId
      )

      const isRelationshipTypeErrorValidationPassed =
        isMultipleIPValidationPassed
          ? await this.relationshipTypeErrorValidaton({
              masterSgaId: masterEntityDetails.sgaId,
              slaveSgaIds: [...duplicateSGAIDs, ...markedDuplicateSgaIds]
            })
          : false
      this.loading = false

      if (
        isCircularHierarchyValidationPassed &&
        isMultipleIPValidationPassed &&
        !isRelationshipTypeErrorValidationPassed
      ) {
        if (!isEmpty(this.dupEntitiesData)) {
          this.duplicateRelationshipsValidationDialog(masterEntityDetails)
        }
      }

      return (
        isUPReferenceValidationPassed &&
        isMultipleParentValidationPassed &&
        isMultipleIPValidationPassed &&
        isCircularHierarchyValidationPassed &&
        isRelationshipTypeErrorValidationPassed
      )
    },

    mapRefRelationshipFields(relationshipRefs) {
      return relationshipRefs.map((ref) => ({
        'Enrichment Entity': `${ref?.enrichmentSgaId} - ${ref?.enrichmentLEN}`,
        'Relationship Type': ref?.relationshipTypes?.join(', ') || '',
        'Assigned To': ref?.assignedToName || '',
        Entity: `${ref?.selfSgaId} - ${ref?.selfLEN}`,
        'Immediate Parent': `${ref?.parentSgaId} - ${ref?.parentLEN}`,
        'Ultimate Parent': `${ref?.ultimateParentSgaId} - ${ref?.ultimateParentLEN}`
      }))
    },

    duplicateRelationshipsValidationDialog(masterEntityDetails = null) {
      const highlightEntities = [
        `${masterEntityDetails.sgaId} - ${masterEntityDetails.legalEntityName}`
      ]
      const hierarchyData = Object.fromEntries(
        Object.entries(this.dupEntitiesData.dupEntities)?.map(
          ([key, values]) => [key, this.mapRefRelationshipFields(values)]
        )
      )

      let descriptionObj = Object.keys(this.dupEntitiesData.dupEntities).reduce(
        (acc, sgaId) => {
          acc[sgaId] = masterEntityDetails
            ? `Multiple relationship of same type detected.<br/>
          While replacing
          <span style='color: #3f6ad8; font-weight: bold;'>${this.dupEntitiesData.dupEntities[
            sgaId
          ]
            .filter((fd) => fd.selfSgaId !== masterEntityDetails.sgaId)
            .map((d) => `${d.selfSgaId} - ${d.selfLEN}`)
            .join(', ')}</span>
          with
          <span style='color: #3f6ad8; font-weight: bold;'>${
            masterEntityDetails.sgaId
          } - ${masterEntityDetails.legalEntityName}</span>.`
            : `Multiple relationship of same type detected.`

          highlightEntities.push(sgaId)
          return acc
        },
        {}
      )

      let multipleSgaIdList = Object.keys(this.dupEntitiesData.dupEntities)

      this.hierarchyDialogInfo = {
        title: 'Relationship type error',
        multipleTableObj: hierarchyData,
        multipleTitleObj: descriptionObj,
        multipleSgaIdList: multipleSgaIdList,
        visible: true,
        highlight: {
          fields: ['Entity', 'Immediate Parent', 'Ultimate Parent'],
          values: highlightEntities,
          columns: ['Relationship Type']
        }
      }
    },

    errorSnackbar() {
      Snackbar({
        message: 'Something went wrong, please try again.',
        type: 'is-danger',
        indefinite: true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.main-container-outer {
  margin: 0 auto;
  .text-msg {
    font: normal normal 13px / 16px Quicksand;
    font-weight: 600;
    letter-spacing: 0.18px;
    height: 200px;
    font-size: large;
    color: #f57777;
    padding: 1em;
  }
  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
.main-container-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

/* Style for the left text */
.left-text {
  text-align: left;
}

/* Style for the right text */
.right-text {
  text-align: right;
}

.main-container-inner2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px 10px 2px 10px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.left-text {
  text-align: left;
}

/* Style for the right text */
.right-text {
  text-align: right;
}

.main-container-inner3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 1px 10px 2px 10px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.entity-info {
  flex: 1 1 auto;

  .info-title {
    margin: 0;
    font-size: 30px;
    letter-spacing: 0px;
    color: var(--inava-primary-dark);
    font-weight: 600;
    display: inline-block;
    margin-right: 15px;
  }

  .date-updated {
    display: inline-block;
    color: #1e1e1e;
    font-size: 18px;
    letter-spacing: 0.18px;
    padding-bottom: 5px;
  }
}

.review-pane {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  gap: 22px;

  .status-info {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #888888;

    .status-box {
      background-color: #c4c8f8;
      padding: 9px 15px;
      border-radius: 5px;
      margin-left: 5px;
      display: flex;
      align-items: center;
      cursor: default;

      span {
        padding-left: 6px;
        font-size: 18px;
        color: var(--inava-primary-dark);
      }
    }
  }

  .review-btn {
    padding: 9px 15px;
    color: #ffffff;
    border-radius: 4px;
    font-size: 18px;
    width: 220px;
  }

  .review-action-btn {
    display: inline-block;
    padding: 9px 15px;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
    font-size: 18px;
    width: 98px;
    cursor: pointer;
  }

  .btn-edit {
    border: 1px solid #888888;
    background-color: #888888;
  }

  .btn-confirm {
    border: 1px solid var(--inava-primary-dark);
    background-color: var(--inava-primary-dark);
  }
}

.button-container {
  display: inline-block;
  padding: 10px;
  background-color: #dfdfdf;
  font-size: 16px;
  border-radius: 4px;
  opacity: 1;
}
.button-text {
  margin: 0;
  font-size: 16px;
  color: #000000;
}

.exception-container {
  display: flex;
  align-items: center;
}

/* Style for the duplicate button */
.duplicate-button {
  padding: 8px;
  background-color: #ffffff;
  color: var(--inava-primary-dark);
  border: 1px solid var(--inava-primary-dark);
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  opacity: 1;
}

.placeholder-text {
  font-size: 18px;
  color: #1e1e1e;
  margin-right: 10px;
}

.sub-text {
  font-size: 16px;
  color: #7d7d7d;
}

.action-button {
  padding: 8px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 16px;
}

.button-icon {
  margin-right: 5px;
}

.blue-button {
  background: var(--inava-primary-dark) 0% 0% no-repeat padding-box;
  border: 1px solid var(--inava-primary-dark);
  border-radius: 4px;
}

.red-button {
  background: #f57777 0% 0% no-repeat padding-box;
  border: 1px solid #f57777;
  border-radius: 4px;
}

.submit-button {
  margin-right: 15px;
  color: #000000;
  padding: 8px;
  border: 1px solid;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  opacity: 1;
}
</style>
