<template>
  <div class="ae-details-section">
    <h2 class="section-title">2052A Details</h2>
    <div class="mandatory-note">
      <p>
        Text boxes marked as
        <span class="mandatory">*</span> are mandatory
      </p>
    </div>
    <div>
      <div class="local-loader-container" v-if="loading">
        <div class="inava-loader"></div>
      </div>
      <div class="ae-form">
        <!-- G-SIB -->
        <div class="column-view">
          <SearchableSelect
            :value="newDetails2052a.gSib"
            label="G-SIB"
            :placeholder="'Select G-SIB'"
            value_field="value"
            text_field="text"
            class="form-input-field"
            @onChangeName="updateGSIB"
            :data="enrichmentAnswerChoices"
            :submitted="submitted"
            :disabled="isFieldDisabled(G_SIB)"
            :isMandatory="isFieldMandatory(G_SIB)"
            :mandatorySubmit="isMandatorySubmit"
            :showSecondaryError="showgsibEvidenceError"
            :secondaryError="validationErrors.gSibEvidence"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(details2052aEvidenceId.GSIB)
              "
              @click.native="toggleEvidenceForm(details2052aEvidenceId.GSIB)"
              :btnLoading="getBtnLoadingStatus(details2052aEvidenceId.GSIB)"
              :isDisabled="isEvidenceBtnDisabled('gSib')"
            />
          </div>
        </div>

        <!-- G-SIB Name -->
        <div class="column-view">
          <SearchableSelect
            :value="newDetails2052a.gSibName"
            label="G-SIB Name"
            :placeholder="'Select G-SIB Name'"
            :disabled="!isgsibNameRequired"
            value_field="value"
            text_field="text"
            class="form-input-field"
            @onChangeName="updateGSIBName"
            :data="gsibNameList"
            :isMandatory="isgsibNameRequired"
            :isDefaultErrorMessage="isMandatorySubmit"
            :mandatorySubmit="isMandatorySubmit"
            :showSecondaryError="showgSibNameEvidenceError"
            :secondaryError="validationErrors.gSibNameEvidence"
            :submitted="submitted"
          />

          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(details2052aEvidenceId.GSIB_NAME)
              "
              @click.native="
                toggleEvidenceForm(details2052aEvidenceId.GSIB_NAME)
              "
              :btnLoading="
                getBtnLoadingStatus(details2052aEvidenceId.GSIB_NAME)
              "
              :isDisabled="isEvidenceBtnDisabled('gSibName')"
            />
          </div>
        </div>

        <!-- Supranational Entity -->
        <div class="column-view">
          <SearchableSelect
            :value="newDetails2052a.supraEntity"
            label="Supranational Entity"
            :placeholder="'Select Supranational Entity'"
            value_field="value"
            text_field="text"
            class="form-input-field"
            @onChangeName="updateSupranationEntity"
            :data="enrichmentAnswerChoices"
            :submitted="submitted"
            :disabled="isFieldDisabled(SUPRANATIONAL_ENTITY)"
            :isMandatory="isFieldMandatory(SUPRANATIONAL_ENTITY)"
            :mandatorySubmit="isMandatorySubmit"
            :showSecondaryError="showSupraEntityEvidenceError"
            :secondaryError="validationErrors.supraEntityEvidence"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(
                  details2052aEvidenceId.SUPRANATIONAL_ENTITY
                )
              "
              @click.native="
                toggleEvidenceForm(details2052aEvidenceId.SUPRANATIONAL_ENTITY)
              "
              :btnLoading="
                getBtnLoadingStatus(details2052aEvidenceId.SUPRANATIONAL_ENTITY)
              "
              :isDisabled="isEvidenceBtnDisabled('supraEntity')"
            />
          </div>
        </div>

        <!-- Sovereign Wealth Fund -->
        <div class="column-view">
          <SearchableSelect
            :value="newDetails2052a.sovWealthFund"
            label="Sovereign Wealth Fund"
            :placeholder="'Select Sovereign Wealth Fund'"
            value_field="value"
            text_field="text"
            class="form-input-field"
            @onChangeName="updateSovereignWealthFund"
            :data="enrichmentAnswerChoices"
            :submitted="submitted"
            :disabled="isFieldDisabled(SOV_WEALTH_FUND)"
            :isMandatory="isFieldMandatory(SOV_WEALTH_FUND)"
            :mandatorySubmit="isMandatorySubmit"
            :showSecondaryError="showSovWealthFundEvidenceError"
            :secondaryError="validationErrors.sovWealthFundEvidence"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(
                  details2052aEvidenceId.SOVEREIGN_WEALTH_FUND
                )
              "
              @click.native="
                toggleEvidenceForm(details2052aEvidenceId.SOVEREIGN_WEALTH_FUND)
              "
              :btnLoading="
                getBtnLoadingStatus(
                  details2052aEvidenceId.SOVEREIGN_WEALTH_FUND
                )
              "
              :isDisabled="isEvidenceBtnDisabled('sovWealthFund')"
            />
          </div>
        </div>

        <!-- Community Development Financial Institution -->
        <div class="column-view">
          <SearchableSelect
            :value="newDetails2052a.comDevFinInst"
            label="Community Development Financial Institution"
            :placeholder="'Select Community Development Financial Institution'"
            value_field="value"
            text_field="text"
            class="form-input-field"
            @onChangeName="updatecomDevFinInst"
            :data="enrichmentAnswerChoices"
            :submitted="submitted"
            :disabled="isFieldDisabled(COMMUNITY_DEV_FIN_INST)"
            :isMandatory="isFieldMandatory(COMMUNITY_DEV_FIN_INST)"
            :mandatorySubmit="isMandatorySubmit"
            :showSecondaryError="showComDevFinInstEvidenceError"
            :secondaryError="validationErrors.comDevFinInstEvidence"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(
                  details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
                )
              "
              @click.native="
                toggleEvidenceForm(
                  details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
                )
              "
              :btnLoading="
                getBtnLoadingStatus(
                  details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
                )
              "
              :isDisabled="isEvidenceBtnDisabled('comDevFinInst')"
            />
          </div>
        </div>

        <!-- Multilateral Development Bank -->
        <div class="column-view">
          <SearchableSelect
            :value="newDetails2052a.multiDevBank"
            label="Multilateral Development Bank"
            :placeholder="'Select Multilateral Development Bank'"
            value_field="value"
            text_field="text"
            class="form-input-field"
            @onChangeName="updateMultilateralDevelopmentBank"
            :data="enrichmentAnswerChoices"
            :submitted="submitted"
            :disabled="isFieldDisabled(MULTILATERAL_DEV_BANK)"
            :isMandatory="isFieldMandatory(MULTILATERAL_DEV_BANK)"
            :mandatorySubmit="isMandatorySubmit"
            :showSecondaryError="showMultiDevBankEvidenceError"
            :secondaryError="validationErrors.multiDevBankEvidence"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(
                  details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK
                )
              "
              @click.native="
                toggleEvidenceForm(
                  details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK
                )
              "
              :btnLoading="
                getBtnLoadingStatus(
                  details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK
                )
              "
              :isDisabled="isEvidenceBtnDisabled('multiDevBank')"
            />
          </div>
        </div>

        <!-- Industrial Loan Bank -->
        <div class="column-view">
          <SearchableSelect
            :value="newDetails2052a.indLoanBank"
            label="Industrial Loan Bank"
            :placeholder="'Select Industrial Loan Bank'"
            value_field="value"
            text_field="text"
            class="form-input-field"
            @onChangeName="updateIndustrialLoanBank"
            :data="enrichmentAnswerChoices"
            :submitted="submitted"
            :disabled="isFieldDisabled(INDUSTRIAL_LOAN_BANK)"
            :isMandatory="isFieldMandatory(INDUSTRIAL_LOAN_BANK)"
            :mandatorySubmit="isMandatorySubmit"
            :showSecondaryError="showIndLoanBankEvidenceError"
            :secondaryError="validationErrors.indLoanBankEvidence"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(
                  details2052aEvidenceId.INDUSTRIAL_LOAN_BANK
                )
              "
              @click.native="
                toggleEvidenceForm(details2052aEvidenceId.INDUSTRIAL_LOAN_BANK)
              "
              :btnLoading="
                getBtnLoadingStatus(details2052aEvidenceId.INDUSTRIAL_LOAN_BANK)
              "
              :isDisabled="isEvidenceBtnDisabled('indLoanBank')"
            />
          </div>
        </div>

        <!-- Balance Sheet Consolidation For Special Purpose -->
        <div class="column-view">
          <SearchableSelect
            :value="newDetails2052a.balSheetConsol"
            label="Balance Sheet Consolidation For Special Purpose"
            :placeholder="'Select Balance Sheet Consolidation For Special Purpose'"
            value_field="value"
            text_field="text"
            class="form-input-field"
            @onChangeName="updateBalanceSheetConsolidation"
            :data="enrichmentAnswerChoices"
            :submitted="submitted"
            :disabled="isFieldDisabled(BALANCE_SHEET_CONSOL)"
            :isMandatory="isFieldMandatory(BALANCE_SHEET_CONSOL)"
            :mandatorySubmit="isMandatorySubmit"
            :showSecondaryError="showBalSheetConsolEvidenceError"
            :secondaryError="validationErrors.balSheetConsolEvidence"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(
                  details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
                )
              "
              @click.native="
                toggleEvidenceForm(
                  details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
                )
              "
              :btnLoading="
                getBtnLoadingStatus(
                  details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
                )
              "
              :isDisabled="isEvidenceBtnDisabled('balSheetConsol')"
            />
          </div>
        </div>

        <!-- Swap Dealer -->
        <div class="column-view">
          <SearchableSelect
            :value="newDetails2052a.swapDealer"
            label="Swap Dealer"
            :placeholder="'Select Swap Dealer'"
            value_field="value"
            text_field="text"
            class="form-input-field"
            @onChangeName="updateSwapDealer"
            :data="enrichmentAnswerChoices"
            :submitted="submitted"
            :isMandatory="isSwapDealerMandatory"
            :disabled="!isSwapDealerMandatory"
            :mandatorySubmit="isMandatorySubmit"
            :showSecondaryError="showSwapDealerEvidenceError"
            :secondaryError="validationErrors.swapDealerEvidence"
            :is-tooltip="true"
            :tooltip-msg="'In order that Swap Dealer field is selectable, please make sure the legal entity name is in alignment with the CFTC website'"
            :isAdditionalInfoLink="true"
            :additionalLinkInfo="additionalLinkInfo"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(details2052aEvidenceId.SWAP_DEALER)
              "
              @click.native="
                toggleEvidenceForm(details2052aEvidenceId.SWAP_DEALER)
              "
              :btnLoading="
                getBtnLoadingStatus(details2052aEvidenceId.SWAP_DEALER)
              "
              :isDisabled="isEvidenceBtnDisabled('swapDealer')"
            />
          </div>
        </div>
      </div>
    </div>
    <template>
      <ActionButton
        @SaveData="saveData"
        @saveAndNext="saveAndNext"
        @goBack="goBack"
        :isFormValid="isValidForm"
      ></ActionButton>
    </template>
    <template>
      <confirmation-dialog
        :visible="confirmationDialogData.visible"
        :title="confirmationDialogData.title"
        :bodyText="confirmationDialogData.text"
        :cancelButtonText="confirmationDialogData.cancelButtonText"
        :confirmButtonText="confirmationDialogData.confirmButtonText"
        :closeDialog="closeDialog"
        :triggerFunction="confirmationDialogData.triggerFunction"
      />
    </template>
  </div>
</template>

<script>
import SearchableSelect from '@/components/DMP/SearchableSelect'
import AddEvidence from '@/components/DMP/AddEvidence.vue'
import ActionButton from '@/components/DMP/ActionButton.vue'
import InputVue from '@/views/Admin/components/Input.vue'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import {
  details2052aEvidenceId,
  activeStepIndexName,
  ENTITY_2052A_FIELD_NAMES
} from '@/constant/data'
import { mapState, mapGetters, mapActions } from 'vuex'
import { isEqual, isEmpty } from 'lodash'

export default {
  name: 'AdetailsForm',
  components: {
    ActionButton,
    AddEvidence,
    InputVue,
    ConfirmationDialog,
    SearchableSelect
  },
  props: {
    updateStepper: {
      type: Function,
      required: true
    }
  },
  data() {
    const {
      G_SIB,
      COMMUNITY_DEV_FIN_INST,
      MULTILATERAL_DEV_BANK,
      INDUSTRIAL_LOAN_BANK,
      BALANCE_SHEET_CONSOL,
      SUPRANATIONAL_ENTITY,
      SOV_WEALTH_FUND,
      SWAP_DEALER
    } = ENTITY_2052A_FIELD_NAMES
    return {
      newDetails2052a: {
        gSib: null,
        gSibEvidence: null,
        gSibName: '',
        gSibNameEvidence: null,
        supraEntity: null,
        supraEntityEvidence: null,
        multiDevBank: null,
        multiDevBankEvidence: null,
        sovWealthFund: null,
        sovWealthFundEvidence: null,
        comDevFinInst: null,
        comDevFinInstEvidence: null,
        indLoanBank: null,
        indLoanBankEvidence: null,
        swapDealer: null,
        swapDealerEvidence: null,
        balSheetConsol: null,
        balSheetConsolEvidence: null
      },
      details2052aEvidenceId,
      activeStepIndexName,
      submitted: false,
      confirmationDialogData: {
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      },
      validationErrors: {
        gSibEvidence: '',
        gSibNameEvidence: '',
        supraEntityEvidence: '',
        multiDevBankEvidence: '',
        sovWealthFundEvidence: '',
        comDevFinInstEvidence: '',
        indLoanBankEvidence: '',
        swapDealerEvidence: '',
        balSheetConsolEvidence: ''
      },
      evidenceIdKeyMapping: {
        [details2052aEvidenceId.GSIB]: 'gSibEvidence',
        [details2052aEvidenceId.GSIB_NAME]: 'gSibNameEvidence',
        [details2052aEvidenceId.SUPRANATIONAL_ENTITY]: 'supraEntityEvidence',
        [details2052aEvidenceId.SOVEREIGN_WEALTH_FUND]: 'sovWealthFundEvidence',
        [details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION]:
          'comDevFinInstEvidence',
        [details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK]:
          'multiDevBankEvidence',
        [details2052aEvidenceId.INDUSTRIAL_LOAN_BANK]: 'indLoanBankEvidence',
        [details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE]:
          'balSheetConsolEvidence',
        [details2052aEvidenceId.SWAP_DEALER]: 'swapDealerEvidence'
      },
      loading: false,
      inputNameMapping: {},
      isPartiallySaved: false,
      isMandatorySubmit: false,
      G_SIB,
      COMMUNITY_DEV_FIN_INST,
      MULTILATERAL_DEV_BANK,
      INDUSTRIAL_LOAN_BANK,
      BALANCE_SHEET_CONSOL,
      SUPRANATIONAL_ENTITY,
      SOV_WEALTH_FUND,
      SWAP_DEALER,
      ENTITY_TYPE_MANDATORY_2052A_FIELDS: {
        ENTITY_TYPE_1: [
          G_SIB,
          COMMUNITY_DEV_FIN_INST,
          MULTILATERAL_DEV_BANK,
          INDUSTRIAL_LOAN_BANK
        ],
        ENTITY_TYPE_12: [BALANCE_SHEET_CONSOL],
        SWAP_DEALER: [SWAP_DEALER],
        OTHER_ENTITY: [SUPRANATIONAL_ENTITY, SOV_WEALTH_FUND]
      },
      additionalLinkInfo: {
        url: 'https://www.cftc.gov/IndustryOversight/Intermediaries/SDs/index.htm',
        text: ' (CFTC)'
      }
    }
  },
  computed: {
    ...mapState('details2052a', [
      'gsibNameList',
      'details2052a',
      'enrichmentAnswerChoices',
      'isSwapDealersMandatory'
    ]),
    ...mapState('evidence', ['evidenceIdList']),
    ...mapState('basicDetails', ['basicDetails']),
    ...mapGetters('evidence', ['getEvidenceFormStatus', 'getBtnLoadingStatus']),
    ...mapGetters('dmp', ['getEntityDetails']),
    isValidForm() {
      // check if gsib is mandatory and then add the validation
      const isValidGSib = this.isFieldMandatory(this.G_SIB)
        ? ![null, ''].includes(this.newDetails2052a.gSib)
        : true

      const isValidgSibName =
        this.newDetails2052a.gSib && this.newDetails2052a.gSib.value === 1
          ? ![null, ''].includes(this.newDetails2052a.gSibName)
          : true

      const isValidSupraEntity = this.isFieldMandatory(
        this.SUPRANATIONAL_ENTITY
      )
        ? ![null, ''].includes(this.newDetails2052a.supraEntity)
        : true

      const isValidSovWealthFund = this.isFieldMandatory(this.SOV_WEALTH_FUND)
        ? ![null, ''].includes(this.newDetails2052a.sovWealthFund)
        : true

      const isValidComDevFinInst = this.isFieldMandatory(
        this.COMMUNITY_DEV_FIN_INST
      )
        ? ![null, ''].includes(this.newDetails2052a.comDevFinInst)
        : true

      const isValidMultiDevBank = this.isFieldMandatory(
        this.MULTILATERAL_DEV_BANK
      )
        ? ![null, ''].includes(this.newDetails2052a.multiDevBank)
        : true

      const isValidIndLoanBank = this.isFieldMandatory(
        this.INDUSTRIAL_LOAN_BANK
      )
        ? ![null, ''].includes(this.newDetails2052a.indLoanBank)
        : true

      const isValidBalSheetConsol = this.isFieldMandatory(
        this.BALANCE_SHEET_CONSOL
      )
        ? ![null, ''].includes(this.newDetails2052a.balSheetConsol)
        : true

      const isValidSwapDealers = this.isSwapDealerMandatory
        ? ![null, ''].includes(this.newDetails2052a.swapDealer)
        : true

      return (
        isValidGSib &&
        isValidgSibName &&
        isValidSupraEntity &&
        isValidSovWealthFund &&
        isValidComDevFinInst &&
        isValidMultiDevBank &&
        isValidIndLoanBank &&
        isValidBalSheetConsol &&
        isValidSwapDealers
      )
    },
    isgsibNameRequired() {
      return (
        (this.newDetails2052a.gSib && this.newDetails2052a.gSib.value === 1) ||
        false
      )
    },
    showgsibEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isgSibChanged, isgSibEvidenceChanged } = changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isgSibChanged || isgSibEvidenceChanged) &&
        ![null, ''].includes(this.validationErrors.gSibEvidence)
      )
    },
    showgSibNameEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isgSibNameChanged, isgSibNameEvidenceChanged } = changedFields

      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isgSibNameChanged || isgSibNameEvidenceChanged) &&
        ![null, ''].includes(this.validationErrors.gSibNameEvidence)
      )
    },
    showSupraEntityEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { issupraEntityChanged, issupraEntityEvidenceChanged } =
        changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (issupraEntityChanged || issupraEntityEvidenceChanged) &&
        ![null, ''].includes(this.validationErrors.supraEntityEvidence)
      )
    },
    showSovWealthFundEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { issovWealthFundChanged, issovWealthFundEvidenceChanged } =
        changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (issovWealthFundChanged || issovWealthFundEvidenceChanged) &&
        ![null, ''].includes(this.validationErrors.sovWealthFundEvidence)
      )
    },
    showComDevFinInstEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { iscomDevFinInstChanged, iscomDevFinInstEvidenceChanged } =
        changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (iscomDevFinInstChanged || iscomDevFinInstEvidenceChanged) &&
        ![null, ''].includes(this.validationErrors.comDevFinInstEvidence)
      )
    },
    showMultiDevBankEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { ismultiDevBankChanged, ismultiDevBankEvidenceChanged } =
        changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (ismultiDevBankChanged || ismultiDevBankEvidenceChanged) &&
        ![null, ''].includes(this.validationErrors.multiDevBankEvidence)
      )
    },
    showIndLoanBankEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isindLoanBankChanged, isindLoanBankEvidenceChanged } =
        changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isindLoanBankChanged || isindLoanBankEvidenceChanged) &&
        ![null, ''].includes(this.validationErrors.indLoanBankEvidence)
      )
    },
    showBalSheetConsolEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isbalSheetConsolChanged, isbalSheetConsolEvidenceChanged } =
        changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isbalSheetConsolChanged || isbalSheetConsolEvidenceChanged) &&
        ![null, ''].includes(this.validationErrors.balSheetConsolEvidence)
      )
    },
    showSwapDealerEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isswapDealerChanged, isswapDealerEvidenceChanged } = changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isswapDealerChanged || isswapDealerEvidenceChanged) &&
        ![null, ''].includes(this.validationErrors.swapDealerEvidence)
      )
    },
    // Corrected entity type access
    entityTypeId() {
      return this.basicDetails?.entityTypeId || null
    },
    isSwapDealerMandatory() {
      // Check if the Swap Dealer should be mandatory
      return this.isSwapDealersMandatory || false
    },
    isEntityType1() {
      // Identify if the entity type is 1
      return this.entityTypeId == 1
    },
    isEntityType12() {
      // Identify if the entity type is 12
      return this.entityTypeId == 12
    },
    isOtherEntityType() {
      // If entity type is neither 1 nor 12
      return this.entityTypeId != 1 && this.entityTypeId != 12
    },
    // List of mandatory fields based on conditions using constants
    mandatoryFields() {
      if (this.isEntityType1) {
        return this.ENTITY_TYPE_MANDATORY_2052A_FIELDS.ENTITY_TYPE_1
      }
      if (this.isEntityType12) {
        return this.ENTITY_TYPE_MANDATORY_2052A_FIELDS.ENTITY_TYPE_12
      }
      if (this.isSwapDealer) {
        return this.ENTITY_TYPE_MANDATORY_2052A_FIELDS.SWAP_DEALER
      }
      if (this.isOtherEntityType) {
        return this.ENTITY_TYPE_MANDATORY_2052A_FIELDS.OTHER_ENTITY
      }
      return []
    },

    // Function to handle disabling of non-mandatory fields
    nonMandatoryFieldsDisabled() {
      const mandatoryFields = this.mandatoryFields
      const allFields = Object.values(ENTITY_2052A_FIELD_NAMES)

      // Disable non-mandatory fields
      return allFields.reduce((acc, field) => {
        acc[field] = !mandatoryFields.includes(field)
        return acc
      }, {})
    },
    findEnrichmentChoicesYesValue() {
      // If enrichment answer choices are not defined, return null to avoid errors
      if (
        !this.enrichmentAnswerChoices ||
        this.enrichmentAnswerChoices.length === 0
      ) {
        return null
      }

      // Get the enrichment answer choice that represents "Yes", case-insensitively
      return this.enrichmentAnswerChoices.find((choice) => choice.value == 1)
    },
    findEnrichmentChoicesNoValue() {
      // If enrichment answer choices are not defined, return null to avoid errors
      if (
        !this.enrichmentAnswerChoices ||
        this.enrichmentAnswerChoices.length === 0
      ) {
        return null
      }
      // Get the enrichment answer choice that represents "No", case-insensitively
      return this.enrichmentAnswerChoices.find((choice) => choice.value == 2)
    },
    ENTITY_2052A_FIELD_NAMES() {
      return ENTITY_2052A_FIELD_NAMES
    }
  },
  watch: {
    details2052a() {
      this.syncNew2052aDetails()
    },
    newDetails2052a: {
      handler() {
        const isgSibChanged =
          this.newDetails2052a.gSib !== null
            ? this.newDetails2052a.gSib.value !==
              (this.details2052a ? this.details2052a.gSib : null)
            : this.newDetails2052a.gSib !==
              (this.details2052a ? this.details2052a.gSib : null)

        const newDetailValue = this.newDetails2052a?.gSibName?.text
          ? this.newDetails2052a?.gSibName?.text
          : null
        const datailApiValue = this.details2052a?.gSibName
          ? this.details2052a?.gSibName
          : null

        const isgSibNameChanged = newDetailValue !== datailApiValue

        const issupraEntityChanged =
          this.newDetails2052a.supraEntity !== null
            ? this.newDetails2052a.supraEntity.value !==
              (this.details2052a ? this.details2052a.supraEntity : null)
            : this.newDetails2052a.supraEntity !==
              (this.details2052a ? this.details2052a.supraEntity : null)
        const issovWealthFundChanged =
          this.newDetails2052a.sovWealthFund !== null
            ? this.newDetails2052a.sovWealthFund.value !==
              (this.details2052a ? this.details2052a.sovWealthFund : null)
            : this.newDetails2052a.sovWealthFund !==
              (this.details2052a ? this.details2052a.sovWealthFund : null)
        const iscomDevFinInstChanged =
          this.newDetails2052a.comDevFinInst !== null
            ? this.newDetails2052a.comDevFinInst.value !==
              (this.details2052a ? this.details2052a.comDevFinInst : null)
            : this.newDetails2052a.comDevFinInst !==
              (this.details2052a ? this.details2052a.comDevFinInst : null)
        const ismultiDevBankChanged =
          this.newDetails2052a.multiDevBank !== null
            ? this.newDetails2052a.multiDevBank.value !==
              (this.details2052a ? this.details2052a.multiDevBank : null)
            : this.newDetails2052a.multiDevBank !==
              (this.details2052a ? this.details2052a.multiDevBank : null)
        const isindLoanBankChanged =
          this.newDetails2052a.indLoanBank !== null
            ? this.newDetails2052a.indLoanBank.value !==
              (this.details2052a ? this.details2052a.indLoanBank : null)
            : this.newDetails2052a.indLoanBank !==
              (this.details2052a ? this.details2052a.indLoanBank : null)
        const isbalSheetConsolChanged =
          this.newDetails2052a.balSheetConsol !== null
            ? this.newDetails2052a.balSheetConsol.value !==
              (this.details2052a ? this.details2052a.balSheetConsol : null)
            : this.newDetails2052a.balSheetConsol !==
              (this.details2052a ? this.details2052a.balSheetConsol : null)
        const isswapDealerChanged =
          this.newDetails2052a.swapDealer !== null
            ? this.newDetails2052a.swapDealer.value !==
              (this.details2052a ? this.details2052a.swapDealer : null)
            : this.newDetails2052a.swapDealer !==
              (this.details2052a ? this.details2052a.swapDealer : null)

        if (!isgSibChanged) {
          this.removeEvidence(details2052aEvidenceId.GSIB)
          if (this.details2052a?.gSibEvidence) {
            this.updateEvidenceIdList(details2052aEvidenceId.GSIB)
            this.updateInputNameMapping(
              details2052aEvidenceId.GSIB,
              this.details2052a.gSibEvidence
            )
          }
        }

        if (!isgSibNameChanged) {
          this.removeEvidence(details2052aEvidenceId.GSIB_NAME)
          if (this.details2052a?.gSibNameEvidence) {
            this.updateEvidenceIdList(details2052aEvidenceId.GSIB_NAME)
            this.updateInputNameMapping(
              details2052aEvidenceId.GSIB_NAME,
              this.details2052a.gSibNameEvidence
            )
          }
        }

        if (!issupraEntityChanged) {
          this.removeEvidence(details2052aEvidenceId.SUPRANATIONAL_ENTITY)
          if (this.details2052a?.supraEntityEvidence) {
            this.updateEvidenceIdList(
              details2052aEvidenceId.SUPRANATIONAL_ENTITY
            )
            this.updateInputNameMapping(
              details2052aEvidenceId.SUPRANATIONAL_ENTITY,
              this.details2052a.supraEntityEvidence
            )
          }
        }

        if (!issovWealthFundChanged) {
          this.removeEvidence(details2052aEvidenceId.SOVEREIGN_WEALTH_FUND)
          if (this.details2052a?.sovWealthFundEvidence) {
            this.updateEvidenceIdList(
              details2052aEvidenceId.SOVEREIGN_WEALTH_FUND
            )
            this.updateInputNameMapping(
              details2052aEvidenceId.SOVEREIGN_WEALTH_FUND,
              this.details2052a.sovWealthFundEvidence
            )
          }
        }

        if (!iscomDevFinInstChanged) {
          this.removeEvidence(
            details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
          )
          if (this.details2052a?.comDevFinInstEvidence) {
            this.updateEvidenceIdList(
              details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
            )
            this.updateInputNameMapping(
              details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION,
              this.details2052a.comDevFinInstEvidence
            )
          }
        }

        if (!ismultiDevBankChanged) {
          this.removeEvidence(
            details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK
          )
          if (this.details2052a?.multiDevBankEvidence) {
            this.updateEvidenceIdList(
              details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK
            )
            this.updateInputNameMapping(
              details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK,
              this.details2052a.multiDevBankEvidence
            )
          }
        }

        if (!isindLoanBankChanged) {
          this.removeEvidence(details2052aEvidenceId.INDUSTRIAL_LOAN_BANK)
          if (this.details2052a?.indLoanBankEvidence) {
            this.updateEvidenceIdList(
              details2052aEvidenceId.INDUSTRIAL_LOAN_BANK
            )
            this.updateInputNameMapping(
              details2052aEvidenceId.INDUSTRIAL_LOAN_BANK,
              this.details2052a.indLoanBankEvidence
            )
          }
        }

        if (!isbalSheetConsolChanged) {
          this.removeEvidence(
            details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
          )
          if (this.details2052a?.balSheetConsolEvidence) {
            this.updateEvidenceIdList(
              details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
            )
            this.updateInputNameMapping(
              details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE,
              this.details2052a.balSheetConsolEvidence
            )
          }
        }

        if (!isswapDealerChanged) {
          this.removeEvidence(details2052aEvidenceId.SWAP_DEALER)
          if (this.details2052a?.swapDealerEvidence) {
            this.updateEvidenceIdList(details2052aEvidenceId.SWAP_DEALER)
            this.updateInputNameMapping(
              details2052aEvidenceId.SWAP_DEALER,
              this.details2052a.swapDealerEvidence
            )
          }
        }
      },
      deep: true
    },

    evidenceIdList: {
      // check for evidence from the vuex store, in case if any is present, then remove the error
      handler(newEvidenceList) {
        const newEvidenceListMapping = newEvidenceList.map(
          (item) => this.evidenceIdKeyMapping[item]
        )
        for (const evidenceKey in this.validationErrors) {
          // Check if the evidenceKey is present in the newEvidenceList
          if (newEvidenceListMapping.includes(evidenceKey)) {
            // Set the error for this evidence to null
            this.validationErrors[evidenceKey] = null
          }
        }
      }
    },
    entityTypeId() {
      this.setDefaultNonMandatoryFields()
    }
  },
  async mounted() {
    try {
      this.loading = true
      const { sgaId } = this.getEntityDetails
      this.sgaId = sgaId
      this.resetEvidenceStore()
      await this.getGsibName()
      await this.getEnrichmentAnswerChoices()
      await this.get2052aDetails(this.sgaId)
      await this.getSwapDealers()

      // Set default values for non-mandatory fields
      this.setDefaultNonMandatoryFields()
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('details2052a', [
      'get2052aDetails',
      'update2052aDetails',
      'getEnrichmentAnswerChoices',
      'getGsibName',
      'getSwapDealers'
    ]),
    ...mapActions('evidence', [
      'uploadEvidence',
      'updateEvidenceIdList',
      'resetEvidenceStore',
      'removeEvidence'
    ]),
    ...mapActions('assignedEntities', ['checkAndUpdateTodoStatus']),
    syncNew2052aDetails() {
      if (this.details2052a) {
        this.newDetails2052a = {
          ...this.newDetails2052a,
          gSib: this.extractEnrichmentAnwerChoice(this.details2052a.gSib),
          gSibEvidence: this.details2052a.gSibEvidence,
          gSibName: this.extractgsibName(this.details2052a.gSibName),

          gSibNameEvidence: this.details2052a.gSibNameEvidence,
          supraEntity: this.extractEnrichmentAnwerChoice(
            this.details2052a.supraEntity
          ),
          supraEntityEvidence: this.details2052a.supraEntityEvidence,
          multiDevBank: this.extractEnrichmentAnwerChoice(
            this.details2052a.multiDevBank
          ),
          multiDevBankEvidence: this.details2052a.multiDevBankEvidence,
          sovWealthFund: this.extractEnrichmentAnwerChoice(
            this.details2052a.sovWealthFund
          ),
          sovWealthFundEvidence: this.details2052a.sovWealthFundEvidence,
          comDevFinInst: this.extractEnrichmentAnwerChoice(
            this.details2052a.comDevFinInst
          ),
          comDevFinInstEvidence: this.details2052a.comDevFinInstEvidence,
          indLoanBank: this.extractEnrichmentAnwerChoice(
            this.details2052a.indLoanBank
          ),
          indLoanBankEvidence: this.details2052a.indLoanBankEvidence,
          swapDealer: this.extractEnrichmentAnwerChoice(
            this.details2052a.swapDealer
          ),
          swapDealerEvidence: this.details2052a.swapDealerEvidence,
          balSheetConsol: this.extractEnrichmentAnwerChoice(
            this.details2052a.balSheetConsol
          ),
          balSheetConsolEvidence: this.details2052a.balSheetConsolEvidence
        }
      }

      if (this.newDetails2052a.gSibEvidence) {
        this.updateEvidenceIdList(details2052aEvidenceId.GSIB)
        this.updateInputNameMapping(
          details2052aEvidenceId.GSIB,
          this.newDetails2052a.gSibEvidence
        )
      }

      if (this.newDetails2052a.gSibNameEvidence) {
        this.updateEvidenceIdList(details2052aEvidenceId.GSIB_NAME)
        this.updateInputNameMapping(
          details2052aEvidenceId.GSIB_NAME,
          this.newDetails2052a.gSibNameEvidence
        )
      }

      if (this.newDetails2052a.supraEntityEvidence) {
        this.updateEvidenceIdList(details2052aEvidenceId.SUPRANATIONAL_ENTITY)
        this.updateInputNameMapping(
          details2052aEvidenceId.SUPRANATIONAL_ENTITY,
          this.newDetails2052a.supraEntityEvidence
        )
      }

      if (this.newDetails2052a.sovWealthFundEvidence) {
        this.updateEvidenceIdList(details2052aEvidenceId.SOVEREIGN_WEALTH_FUND)
        this.updateInputNameMapping(
          details2052aEvidenceId.SOVEREIGN_WEALTH_FUND,
          this.newDetails2052a.sovWealthFundEvidence
        )
      }

      if (this.newDetails2052a.comDevFinInstEvidence) {
        this.updateEvidenceIdList(
          details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
        )
        this.updateInputNameMapping(
          details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION,
          this.newDetails2052a.comDevFinInstEvidence
        )
      }

      if (this.newDetails2052a.multiDevBankEvidence) {
        this.updateEvidenceIdList(
          details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK
        )
        this.updateInputNameMapping(
          details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK,
          this.newDetails2052a.multiDevBankEvidence
        )
      }

      if (this.newDetails2052a.indLoanBankEvidence) {
        this.updateEvidenceIdList(details2052aEvidenceId.INDUSTRIAL_LOAN_BANK)
        this.updateInputNameMapping(
          details2052aEvidenceId.INDUSTRIAL_LOAN_BANK,
          this.newDetails2052a.indLoanBankEvidence
        )
      }

      if (this.newDetails2052a.balSheetConsolEvidence) {
        this.updateEvidenceIdList(
          details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
        )
        this.updateInputNameMapping(
          details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE,
          this.newDetails2052a.balSheetConsolEvidence
        )
      }

      if (this.newDetails2052a.swapDealerEvidence) {
        this.updateEvidenceIdList(details2052aEvidenceId.SWAP_DEALER)
        this.updateInputNameMapping(
          details2052aEvidenceId.SWAP_DEALER,
          this.newDetails2052a.swapDealerEvidence
        )
      }
    },
    // This functionchecking if user proceed without save the changes,for now returning true. need to refactor in upoming sprint once poc completed for basic details
    shouldAllowFormSwitch() {
      return true
    },
    extractEnrichmentAnwerChoice(choiceValue) {
      if (choiceValue !== null) {
        const foundChoice = this.enrichmentAnswerChoices.find(
          (choice) => choice.value === choiceValue
        )
        return foundChoice !== undefined ? foundChoice : null
      }
      return null
    },
    extractgsibName(choiceName) {
      if (choiceName !== null) {
        const foundChoice = this.gsibNameList.find(
          (choice) => choice.text === choiceName
        )
        return foundChoice !== undefined ? foundChoice : null
      }
      return null
    },
    goBack() {
      // Check if there are unsaved changes
      const { hasUnsavedChanges } = this.hasUnsavedChangesFunc()
      if (hasUnsavedChanges) {
        this.confirmationDialogData.visible = true
        this.confirmationDialogData.title = 'Discard changes?'
        this.confirmationDialogData.text =
          'Would you like to discard the changes & go back <br>to AnaCredit Details screen?'
        this.confirmationDialogData.triggerFunction = this.resetFormData // this is the function that will be triggered on the confirm button
      } else {
        this.goBackToPreviousForm()
      }
    },

    hasUnsavedChangesFunc() {
      // Compare each form field with its initial value
      const isgSibChanged =
        this.newDetails2052a.gSib !== null
          ? this.newDetails2052a.gSib.value !==
            (this.details2052a ? this.details2052a.gSib : null)
          : this.newDetails2052a.gSib !==
            (this.details2052a ? this.details2052a.gSib : null)

      const newDetailValue = this.newDetails2052a?.gSibName?.text
        ? this.newDetails2052a?.gSibName?.text
        : null

      const datailApiValue = this.details2052a?.gSibName
        ? this.details2052a?.gSibName
        : null

      const isgSibNameChanged = newDetailValue !== datailApiValue

      const issupraEntityChanged =
        this.newDetails2052a.supraEntity !== null
          ? this.newDetails2052a.supraEntity.value !==
            (this.details2052a ? this.details2052a.supraEntity : null)
          : this.newDetails2052a.supraEntity !==
            (this.details2052a ? this.details2052a.supraEntity : null)
      const issovWealthFundChanged =
        this.newDetails2052a.sovWealthFund !== null
          ? this.newDetails2052a.sovWealthFund.value !==
            (this.details2052a ? this.details2052a.sovWealthFund : null)
          : this.newDetails2052a.sovWealthFund !==
            (this.details2052a ? this.details2052a.sovWealthFund : null)
      const iscomDevFinInstChanged =
        this.newDetails2052a.comDevFinInst !== null
          ? this.newDetails2052a.comDevFinInst.value !==
            (this.details2052a ? this.details2052a.comDevFinInst : null)
          : this.newDetails2052a.comDevFinInst !==
            (this.details2052a ? this.details2052a.comDevFinInst : null)
      const ismultiDevBankChanged =
        this.newDetails2052a.multiDevBank !== null
          ? this.newDetails2052a.multiDevBank.value !==
            (this.details2052a ? this.details2052a.multiDevBank : null)
          : this.newDetails2052a.multiDevBank !==
            (this.details2052a ? this.details2052a.multiDevBank : null)
      const isindLoanBankChanged =
        this.newDetails2052a.indLoanBank !== null
          ? this.newDetails2052a.indLoanBank.value !==
            (this.details2052a ? this.details2052a.indLoanBank : null)
          : this.newDetails2052a.indLoanBank !==
            (this.details2052a ? this.details2052a.indLoanBank : null)
      const isbalSheetConsolChanged =
        this.newDetails2052a.balSheetConsol !== null
          ? this.newDetails2052a.balSheetConsol.value !==
            (this.details2052a ? this.details2052a.balSheetConsol : null)
          : this.newDetails2052a.balSheetConsol !==
            (this.details2052a ? this.details2052a.balSheetConsol : null)
      const isswapDealerChanged =
        this.newDetails2052a.swapDealer !== null
          ? this.newDetails2052a.swapDealer.value !==
            (this.details2052a ? this.details2052a.swapDealer : null)
          : this.newDetails2052a.swapDealer !==
            (this.details2052a ? this.details2052a.swapDealer : null)

      const isgSibEvidenceChanged =
        this.details2052a && this.details2052a.gSibEvidence
          ? !this.isEvidencePresentInInputMapping(details2052aEvidenceId.GSIB)
          : this.isEvidencePresentInInputMapping(details2052aEvidenceId.GSIB)
      const isgSibNameEvidenceChanged =
        this.details2052a && this.details2052a.gSibNameEvidence
          ? !this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.GSIB_NAME
            )
          : this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.GSIB_NAME
            )
      const issupraEntityEvidenceChanged =
        this.details2052a && this.details2052a.supraEntityEvidence
          ? !this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.SUPRANATIONAL_ENTITY
            )
          : this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.SUPRANATIONAL_ENTITY
            )
      const issovWealthFundEvidenceChanged =
        this.details2052a && this.details2052a.sovWealthFundEvidence
          ? !this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.SOVEREIGN_WEALTH_FUND
            )
          : this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.SOVEREIGN_WEALTH_FUND
            )
      const iscomDevFinInstEvidenceChanged =
        this.details2052a && this.details2052a.comDevFinInstEvidence
          ? !this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
            )
          : this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
            )
      const ismultiDevBankEvidenceChanged =
        this.details2052a && this.details2052a.multiDevBankEvidence
          ? !this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK
            )
          : this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK
            )
      const isindLoanBankEvidenceChanged =
        this.details2052a && this.details2052a.indLoanBankEvidence
          ? !this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.INDUSTRIAL_LOAN_BANK
            )
          : this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.INDUSTRIAL_LOAN_BANK
            )
      const isbalSheetConsolEvidenceChanged =
        this.details2052a && this.details2052a.balSheetConsolEvidence
          ? !this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
            )
          : this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
            )
      const isswapDealerEvidenceChanged =
        this.details2052a && this.details2052a.swapDealerEvidence
          ? !this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.SWAP_DEALER
            )
          : this.isEvidencePresentInInputMapping(
              details2052aEvidenceId.SWAP_DEALER
            )

      const changedFields = {
        isgSibChanged,
        isgSibNameChanged,
        issupraEntityChanged,
        issovWealthFundChanged,
        iscomDevFinInstChanged,
        ismultiDevBankChanged,
        isindLoanBankChanged,
        isbalSheetConsolChanged,
        isswapDealerChanged,
        isgSibEvidenceChanged,
        isgSibNameEvidenceChanged,
        issupraEntityEvidenceChanged,
        issovWealthFundEvidenceChanged,
        iscomDevFinInstEvidenceChanged,
        ismultiDevBankEvidenceChanged,
        isindLoanBankEvidenceChanged,
        isbalSheetConsolEvidenceChanged,
        isswapDealerEvidenceChanged
      }

      if (
        !isgSibChanged &&
        !isgSibNameChanged &&
        !issupraEntityChanged &&
        !issovWealthFundChanged &&
        !iscomDevFinInstChanged &&
        !ismultiDevBankChanged &&
        !isindLoanBankChanged &&
        !isbalSheetConsolChanged &&
        !isswapDealerChanged &&
        !isgSibEvidenceChanged &&
        !isgSibNameEvidenceChanged &&
        !issupraEntityEvidenceChanged &&
        !issovWealthFundEvidenceChanged &&
        !iscomDevFinInstEvidenceChanged &&
        !ismultiDevBankEvidenceChanged &&
        !isindLoanBankEvidenceChanged &&
        !isbalSheetConsolEvidenceChanged &&
        !isswapDealerEvidenceChanged
      ) {
        return {
          hasUnsavedChanges: false,
          changedFields
        }
      } else {
        return {
          hasUnsavedChanges: true,
          changedFields
        }
      }
    },
    isEvidencePresentInInputMapping(inputName) {
      return this.evidenceIdList.includes(inputName)
    },
    closeDialog() {
      this.confirmationDialogData = {
        ...this.confirmationDialogData,
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      }
    },

    resetFormData() {
      this.syncNew2052aDetails()
      this.goBackToPreviousForm()
      // Implement a function to reset form data to its initial state
    },
    goBackToPreviousForm() {
      this.updateStepper(this.activeStepIndexName.anaCreditDetails)
    },
    isValidFormFields() {
      return this.newDetails2052a.gSibName
        ? this.newDetails2052a.gSibName
        : true
    },

    async saveAndNext() {
      this.loading = true
      this.isMandatorySubmit = true
      this.submitted = true
      const isValidFormData = this.isValidForm
      const isValidEvidences = await this.checkForValidEvidences()
      if (isValidFormData && isValidEvidences) {
        this.clearEvidenceErrors()
        try {
          const responseStatus = await this.createPayloadAndUpdate()
          if (responseStatus && responseStatus === 200) {
            this.updateStepper(this.activeStepIndexName.relationship)
          }
        } catch (error) {
          this.loading = false
        }
      }
      this.loading = false
    },
    async saveData() {
      if (this.isMandatorySubmit) {
        this.isMandatorySubmit = false
      }

      this.submitted = true

      try {
        this.loading = true

        const isValidFields = this.isValidFormFields()
        const isValidEvidences = await this.checkForValidEvidences()

        if (isValidFields && isValidEvidences) {
          this.clearEvidenceErrors()
          await this.createPayloadAndUpdate()
        }

        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    async checkForValidEvidences() {
      this.evidenceIdMappingResponse = await this.uploadEvidence()
      this.updateEvidenceIdMapping(this.evidenceIdMappingResponse)
      const isValidEvidences = this.checkForEvidenceErrors()
      return isValidEvidences
    },
    updateEvidenceIdMapping(evidenceIdMappingResponse) {
      const evidenceIdMapping = {}
      if (
        evidenceIdMappingResponse &&
        Array.isArray(evidenceIdMappingResponse)
      ) {
        evidenceIdMappingResponse.forEach((item) => {
          if (item.evidenceIds && item.evidenceIds.length > 0) {
            const evidenceId = item ? Number(item.evidenceIds[0].id) : null
            evidenceIdMapping[item.attributeName] = evidenceId
          }
        })
      }

      this.newDetails2052a.gSibEvidence = this.isEvidencePresentInInputMapping(
        details2052aEvidenceId.GSIB
      )
        ? evidenceIdMapping[details2052aEvidenceId.GSIB] ||
          this.details2052a.gSibEvidence
        : null

      this.newDetails2052a.gSibNameEvidence =
        this.isEvidencePresentInInputMapping(details2052aEvidenceId.GSIB_NAME)
          ? evidenceIdMapping[details2052aEvidenceId.GSIB_NAME] ||
            this.details2052a.gSibNameEvidence
          : null

      this.newDetails2052a.supraEntityEvidence =
        this.isEvidencePresentInInputMapping(
          details2052aEvidenceId.SUPRANATIONAL_ENTITY
        )
          ? evidenceIdMapping[details2052aEvidenceId.SUPRANATIONAL_ENTITY] ||
            this.details2052a.supraEntityEvidence
          : null

      this.newDetails2052a.sovWealthFundEvidence =
        this.isEvidencePresentInInputMapping(
          details2052aEvidenceId.SOVEREIGN_WEALTH_FUND
        )
          ? evidenceIdMapping[details2052aEvidenceId.SOVEREIGN_WEALTH_FUND] ||
            this.details2052a.sovWealthFundEvidence
          : null

      this.newDetails2052a.comDevFinInstEvidence =
        this.isEvidencePresentInInputMapping(
          details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
        )
          ? evidenceIdMapping[
              details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
            ] || this.details2052a.comDevFinInstEvidence
          : null

      this.newDetails2052a.multiDevBankEvidence =
        this.isEvidencePresentInInputMapping(
          details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK
        )
          ? evidenceIdMapping[
              details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK
            ] || this.details2052a.multiDevBankEvidence
          : null

      this.newDetails2052a.indLoanBankEvidence =
        this.isEvidencePresentInInputMapping(
          details2052aEvidenceId.INDUSTRIAL_LOAN_BANK
        )
          ? evidenceIdMapping[details2052aEvidenceId.INDUSTRIAL_LOAN_BANK] ||
            this.details2052a.indLoanBankEvidence
          : null

      this.newDetails2052a.balSheetConsolEvidence =
        this.isEvidencePresentInInputMapping(
          details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
        )
          ? evidenceIdMapping[
              details2052aEvidenceId
                .BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
            ] || this.details2052a.balSheetConsolEvidence
          : null

      this.newDetails2052a.swapDealerEvidence =
        this.isEvidencePresentInInputMapping(details2052aEvidenceId.SWAP_DEALER)
          ? evidenceIdMapping[details2052aEvidenceId.SWAP_DEALER] ||
            this.details2052a.swapDealerEvidence
          : null
    },
    async createPayloadAndUpdate() {
      // Check entity status and update it to In Progress, if it's Todo
      await this.checkAndUpdateTodoStatus()
      const payload = {
        ...this.newDetails2052a,
        gSib: this.newDetails2052a.gSib
          ? this.newDetails2052a.gSib.value
          : null,
        gSibName: this.newDetails2052a.gSibName
          ? this.newDetails2052a.gSibName.text
          : null,
        supraEntity: this.newDetails2052a.supraEntity
          ? this.newDetails2052a.supraEntity.value
          : null,
        sovWealthFund: this.newDetails2052a.sovWealthFund
          ? this.newDetails2052a.sovWealthFund.value
          : null,
        comDevFinInst: this.newDetails2052a.comDevFinInst
          ? this.newDetails2052a.comDevFinInst.value
          : null,
        multiDevBank: this.newDetails2052a.multiDevBank
          ? this.newDetails2052a.multiDevBank.value
          : null,
        indLoanBank: this.newDetails2052a.indLoanBank
          ? this.newDetails2052a.indLoanBank.value
          : null,
        balSheetConsol: this.newDetails2052a.balSheetConsol
          ? this.newDetails2052a.balSheetConsol.value
          : null,
        swapDealer: this.newDetails2052a.swapDealer
          ? this.newDetails2052a.swapDealer.value
          : null
      }

      const responseStatus = await this.update2052aDetails({
        sgaId: this.sgaId,
        payloadData: payload
      })

      return responseStatus
    },
    isEvidenceBtnDisabled(inputName) {
      let originalChoice
      const evidenceIdMapping = {
        gSib: this.newDetails2052a.gSibEvidence,
        gSibName: this.newDetails2052a.gSibNameEvidence,
        supraEntity: this.newDetails2052a.supraEntityEvidence,
        sovWealthFund: this.newDetails2052a.sovWealthFundEvidence,
        comDevFinInst: this.newDetails2052a.comDevFinInstEvidence,
        multiDevBank: this.newDetails2052a.multiDevBankEvidence,
        indLoanBank: this.newDetails2052a.indLoanBankEvidence,
        balSheetConsol: this.newDetails2052a.balSheetConsolEvidence,
        swapDealer: this.newDetails2052a.swapDealerEvidence
      }

      if (inputName === 'gSib') {
        originalChoice = this.details2052a
          ? this.enrichmentAnswerChoices.find(
              (choice) => choice.value === this.details2052a.gSib
            ) ?? null
          : null
      }

      if (inputName === 'gSibName') {
        originalChoice = this.details2052a
          ? this.gsibNameList.find(
              (choice) => choice.text === this.details2052a.gSibName
            ) ?? null
          : null
      }

      if (inputName === 'supraEntity') {
        originalChoice = this.details2052a
          ? this.enrichmentAnswerChoices.find(
              (choice) => choice.value === this.details2052a.supraEntity
            ) ?? null
          : null
      }

      if (inputName === 'sovWealthFund') {
        originalChoice = this.details2052a
          ? this.enrichmentAnswerChoices.find(
              (choice) => choice.value === this.details2052a.sovWealthFund
            ) ?? null
          : null
      }

      if (inputName === 'comDevFinInst') {
        originalChoice = this.details2052a
          ? this.enrichmentAnswerChoices.find(
              (choice) => choice.value === this.details2052a.comDevFinInst
            ) ?? null
          : null
      }

      if (inputName === 'multiDevBank') {
        originalChoice = this.details2052a
          ? this.enrichmentAnswerChoices.find(
              (choice) => choice.value === this.details2052a.multiDevBank
            ) ?? null
          : null
      }

      if (inputName === 'indLoanBank') {
        originalChoice = this.details2052a
          ? this.enrichmentAnswerChoices.find(
              (choice) => choice.value === this.details2052a.indLoanBank
            ) ?? null
          : null
      }

      if (inputName === 'balSheetConsol') {
        originalChoice = this.details2052a
          ? this.enrichmentAnswerChoices.find(
              (choice) => choice.value === this.details2052a.balSheetConsol
            ) ?? null
          : null
      }

      if (inputName === 'swapDealer') {
        originalChoice = this.details2052a
          ? this.enrichmentAnswerChoices.find(
              (choice) => choice.value === this.details2052a.swapDealer
            ) || null
          : null
      }
      const isEvidenceAltered = this.isEvidenceChanged(inputName)

      const choiceValue =
        originalChoice &&
        typeof originalChoice === 'object' &&
        'value' in originalChoice
          ? originalChoice.value
          : originalChoice
      const newDetailsFieldValue = this.newDetails2052a[`${inputName}`]
        ? this.newDetails2052a[`${inputName}`].value
        : null
      return (
        !evidenceIdMapping[`${inputName}`] &&
        newDetailsFieldValue === choiceValue &&
        !isEvidenceAltered
      )
    },
    isEvidenceChanged(inputName) {
      let newEvidence, oldEvidence
      switch (inputName) {
        case 'gSib':
          newEvidence = this.newDetails2052a?.gSibEvidence ?? null
          oldEvidence = this.details2052a
            ? this.details2052a.gSibEvidence
            : null
          break
        case 'gSibName':
          newEvidence = this.newDetails2052a?.gSibNameEvidence ?? null
          oldEvidence = this.details2052a
            ? this.details2052a.gSibNameEvidence
            : null
          break
        case 'supraEntity':
          newEvidence = this.newDetails2052a?.supraEntityEvidence ?? null
          oldEvidence = this.details2052a
            ? this.details2052a.supraEntityEvidence
            : null
          break
        case 'sovWealthFund':
          newEvidence = this.newDetails2052a?.sovWealthFundEvidence ?? null
          oldEvidence = this.details2052a
            ? this.details2052a.sovWealthFundEvidence
            : null
          break
        case 'comDevFinInst':
          newEvidence = this.newDetails2052a?.comDevFinInstEvidence ?? null
          oldEvidence = this.details2052a
            ? this.details2052a.comDevFinInstEvidence
            : null
          break
        case 'multiDevBank':
          newEvidence = this.newDetails2052a?.multiDevBankEvidence ?? null
          oldEvidence = this.details2052a
            ? this.details2052a.multiDevBankEvidence
            : null
          break
        case 'indLoanBank':
          newEvidence = this.newDetails2052a?.indLoanBankEvidence ?? null
          oldEvidence = this.details2052a
            ? this.details2052a.indLoanBankEvidence
            : null
          break
        case 'balSheetConsol':
          newEvidence = this.newDetails2052a?.balSheetConsolEvidence ?? null
          oldEvidence = this.details2052a
            ? this.details2052a.balSheetConsolEvidence
            : null
          break
        case 'swapDealer':
          newEvidence = this.newDetails2052a?.swapDealerEvidence ?? null
          oldEvidence = this.details2052a
            ? this.details2052a.swapDealerEvidence
            : null
          break
        default:
          return false
      }
      return newEvidence !== oldEvidence
    },
    async updateGSIBName(value) {
      this.newDetails2052a.gSibName = value
      if (
        !isEqual(
          this.newDetails2052a.gSibName,
          this.extractgsibName(this.details2052a?.gSibName)
        )
      ) {
        this.removeEvidence(details2052aEvidenceId.GSIB_NAME)
      }
    },

    async updateGSIB(value) {
      this.newDetails2052a.gSib = value
      // 2 is for check the enrichment answer choice that represents "No"
      if (
        this.newDetails2052a.gSib === null ||
        this.newDetails2052a.gSib?.value == 2
      ) {
        this.newDetails2052a.gSibName = null
      }

      if (
        !isEqual(
          this.newDetails2052a.gSib,
          this.extractEnrichmentAnwerChoice(this.details2052a?.gSib)
        )
      ) {
        this.removeEvidence(details2052aEvidenceId.GSIB)
      }
    },
    updateSupranationEntity(value) {
      this.newDetails2052a.supraEntity = value
      if (
        !isEqual(
          this.newDetails2052a.supraEntity,
          this.extractEnrichmentAnwerChoice(this.details2052a?.supraEntity)
        )
      ) {
        this.removeEvidence(details2052aEvidenceId.SUPRANATIONAL_ENTITY)
      }
    },
    updateSovereignWealthFund(value) {
      this.newDetails2052a.sovWealthFund = value
      if (
        !isEqual(
          this.newDetails2052a.sovWealthFund,
          this.extractEnrichmentAnwerChoice(this.details2052a?.sovWealthFund)
        )
      ) {
        this.removeEvidence(details2052aEvidenceId.SOVEREIGN_WEALTH_FUND)
      }
    },
    updatecomDevFinInst(value) {
      this.newDetails2052a.comDevFinInst = value
      if (
        !isEqual(
          this.newDetails2052a.comDevFinInst,
          this.extractEnrichmentAnwerChoice(this.details2052a?.comDevFinInst)
        )
      ) {
        this.removeEvidence(
          details2052aEvidenceId.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
        )
      }
    },
    updateMultilateralDevelopmentBank(value) {
      this.newDetails2052a.multiDevBank = value
      if (
        !isEqual(
          this.newDetails2052a.multiDevBank,
          this.extractEnrichmentAnwerChoice(this.details2052a?.multiDevBank)
        )
      ) {
        this.removeEvidence(
          details2052aEvidenceId.MULTILATERAL_DEVELOPMENT_BANK
        )
      }
    },
    updateIndustrialLoanBank(value) {
      this.newDetails2052a.indLoanBank = value
      if (
        !isEqual(
          this.newDetails2052a.indLoanBank,
          this.extractEnrichmentAnwerChoice(this.details2052a?.indLoanBank)
        )
      ) {
        this.removeEvidence(details2052aEvidenceId.INDUSTRIAL_LOAN_BANK)
      }
    },
    updateBalanceSheetConsolidation(value) {
      // Implement the logic to update balance sheet consolidation
      this.newDetails2052a.balSheetConsol = value
      if (
        !isEqual(
          this.newDetails2052a.balSheetConsol,
          this.extractEnrichmentAnwerChoice(this.details2052a?.balSheetConsol)
        )
      ) {
        this.removeEvidence(
          details2052aEvidenceId.BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
        )
      }
    },
    updateSwapDealer(value) {
      this.newDetails2052a.swapDealer = value
      if (
        !isEqual(
          this.newDetails2052a.swapDealer,
          this.extractEnrichmentAnwerChoice(this.details2052a?.swapDealer)
        )
      ) {
        this.removeEvidence(details2052aEvidenceId.SWAP_DEALER)
      }
    },
    async toggleEvidenceForm(inputName) {
      const isViewEvidence = this.getEvidenceFormStatus(inputName)
      const evidenceIds = this.getEvidenceIds(inputName)

      this.$emit('toggleEvidenceForm', {
        inputName,
        isViewEvidence,
        evidenceIds
      })
    },
    getEvidenceIds(evidenceId) {
      return this.inputNameMapping[`${evidenceId}`]
    },
    updateInputNameMapping(inputName, evidenceId) {
      this.$set(this.inputNameMapping, inputName, evidenceId)
    },
    checkForEvidenceErrors() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const {
        isgSibChanged,
        isgSibNameChanged,
        issupraEntityChanged,
        issovWealthFundChanged,
        iscomDevFinInstChanged,
        ismultiDevBankChanged,
        isindLoanBankChanged,
        isbalSheetConsolChanged,
        isswapDealerChanged,
        isgSibEvidenceChanged,
        isgSibNameEvidenceChanged,
        issupraEntityEvidenceChanged,
        issovWealthFundEvidenceChanged,
        iscomDevFinInstEvidenceChanged,
        ismultiDevBankEvidenceChanged,
        isindLoanBankEvidenceChanged,
        isbalSheetConsolEvidenceChanged,
        isswapDealerEvidenceChanged
      } = changedFields

      let isValidgSibEvidence = true
      let isValidgSibNameEvidence = true
      let isValidsupraEntityEvidence = true
      let isValidsovWealthFundEvidence = true
      let isValidcomDevFinInstEvidence = true
      let isValidmultiDevBankEvidence = true
      let isValidindLoanBankEvidence = true
      let isValidbalSheetConsolEvidence = true
      let isValidswapDealerEvidence = true

      if (isgSibChanged || isgSibEvidenceChanged) {
        isValidgSibEvidence = this.validateEvidenceField(
          this.newDetails2052a,
          'gSibEvidence',
          'G-SIB'
        )
      }
      if (isgSibNameChanged || isgSibNameEvidenceChanged) {
        isValidgSibNameEvidence = this.validateEvidenceField(
          this.newDetails2052a,
          'gSibNameEvidence',
          'G-SIB Name'
        )
      }
      if (issupraEntityChanged || issupraEntityEvidenceChanged) {
        isValidsupraEntityEvidence = this.validateEvidenceField(
          this.newDetails2052a,
          'supraEntityEvidence',
          'Supranational Entity'
        )
      }
      if (issovWealthFundChanged || issovWealthFundEvidenceChanged) {
        isValidsovWealthFundEvidence = this.validateEvidenceField(
          this.newDetails2052a,
          'sovWealthFundEvidence',
          'Sovereign Wealth Fund'
        )
      }
      if (iscomDevFinInstChanged || iscomDevFinInstEvidenceChanged) {
        isValidcomDevFinInstEvidence = this.validateEvidenceField(
          this.newDetails2052a,
          'comDevFinInstEvidence',
          'Community Development Financial Institution'
        )
      }
      if (ismultiDevBankChanged || ismultiDevBankEvidenceChanged) {
        isValidmultiDevBankEvidence = this.validateEvidenceField(
          this.newDetails2052a,
          'multiDevBankEvidence',
          'Multilateral Development Bank'
        )
      }
      if (isindLoanBankChanged || isindLoanBankEvidenceChanged) {
        isValidindLoanBankEvidence = this.validateEvidenceField(
          this.newDetails2052a,
          'indLoanBankEvidence',
          'Industrial Loan Bank'
        )
      }
      if (isbalSheetConsolChanged || isbalSheetConsolEvidenceChanged) {
        isValidbalSheetConsolEvidence = this.validateEvidenceField(
          this.newDetails2052a,
          'balSheetConsolEvidence',
          'Balance Sheet Consolidation For Special Purpose'
        )
      }
      if (isswapDealerChanged || isswapDealerEvidenceChanged) {
        isValidswapDealerEvidence = this.validateEvidenceField(
          this.newDetails2052a,
          'swapDealerEvidence',
          'Swap Dealer'
        )
      }

      return (
        isValidgSibEvidence &&
        isValidgSibNameEvidence &&
        isValidsupraEntityEvidence &&
        isValidsovWealthFundEvidence &&
        isValidcomDevFinInstEvidence &&
        isValidmultiDevBankEvidence &&
        isValidindLoanBankEvidence &&
        isValidbalSheetConsolEvidence &&
        isValidswapDealerEvidence
      )
    },
    validateEvidenceField(payloadData, fieldName, errorMessage) {
      const fieldErrorMessage = `Please upload evidence for ${errorMessage}`
      if (!payloadData[fieldName]) {
        this.validationErrors[fieldName] = fieldErrorMessage
        return false
      } else {
        this.validationErrors[fieldName] = ''
      }
      return true
    },
    clearEvidenceErrors() {
      this.validationErrors = {
        ...this.validationErrors,
        gSibEvidence: null,
        gSibNameEvidence: null,
        supraEntityEvidence: null,
        sovWealthFundEvidence: null,
        comDevFinInstEvidence: null,
        multiDevBankEvidence: null,
        indLoanBankEvidence: null,
        balSheetConsolEvidence: null,
        swapDealerEvidence: null
      }
    },
    // Check if the field is mandatory
    isFieldMandatory(fieldName) {
      return this.mandatoryFields.includes(fieldName)
    },

    // Check if the field is disabled
    isFieldDisabled(fieldName) {
      return !this.isFieldMandatory(fieldName)
    },
    setDefaultNonMandatoryFields() {
      const enrichmentChoicesNoValue = this.findEnrichmentChoicesNoValue
      const enrichmentChoicesYesValue = this.findEnrichmentChoicesYesValue

      if (!enrichmentChoicesNoValue && !enrichmentChoicesYesValue) return

      // Set default "No" value for non-mandatory fields if they don't have any existing values
      if (!this.isFieldMandatory(this.G_SIB)) {
        // In case if gsib is non-mandatory, then set the gsib value to "NO" and clear the gsib name
        this.newDetails2052a.gSib = enrichmentChoicesNoValue
        this.newDetails2052a.gSibName = null
      }

      if (!this.isFieldMandatory(this.SUPRANATIONAL_ENTITY)) {
        this.newDetails2052a.supraEntity = enrichmentChoicesNoValue
      }

      if (!this.isFieldMandatory(this.SOV_WEALTH_FUND)) {
        this.newDetails2052a.sovWealthFund = enrichmentChoicesNoValue
      }

      if (!this.isFieldMandatory(this.COMMUNITY_DEV_FIN_INST)) {
        this.newDetails2052a.comDevFinInst = enrichmentChoicesNoValue
      }

      if (!this.isFieldMandatory(this.MULTILATERAL_DEV_BANK)) {
        this.newDetails2052a.multiDevBank = enrichmentChoicesNoValue
      }

      if (!this.isFieldMandatory(this.INDUSTRIAL_LOAN_BANK)) {
        this.newDetails2052a.indLoanBank = enrichmentChoicesNoValue
      }

      if (!this.isFieldMandatory(this.BALANCE_SHEET_CONSOL)) {
        this.newDetails2052a.balSheetConsol = enrichmentChoicesNoValue
      }

      // If swap dealer is mandatory, then set the value to "Yes" if it doesn't have any existing values
      // In case if it's not mandatory, then set the value to "No", regardless of the existing value
      if (this.isSwapDealerMandatory) {
        if (!(this.details2052a && this.details2052a.swapDealer)) {
          this.newDetails2052a.swapDealer = enrichmentChoicesYesValue
        }
      } else {
        this.newDetails2052a.swapDealer = enrichmentChoicesNoValue
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ae-details-section {
  margin-top: 1rem;
  position: relative;

  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .section-title {
    font-size: 21px;
    font-weight: 600;
    color: #00218a;
  }
  .mandatory-note {
    p {
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      font-size: 12px;
      line-height: 15px;
      font-family: Quicksand;
      letter-spacing: 0.14px;
      margin-bottom: 2rem;
    }
    .mandatory {
      color: red;
      font-size: 17px;
    }
  }
  .ae-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .column-view {
      width: 45%;
      // margin-top: 2rem;
      display: flex;
      flex-direction: row;
      // gap: 1.2rem;
      margin-bottom: 2rem;

      .form-input-field {
        width: 100%;
        p {
          display: flex;
          margin: 0px;
          color: #7d7d7d;
          font-size: 12px;
          font-family: Quicksand;
          padding: 0px 20px 0px 0px;
          display: flex;
          line-height: 20px;
          min-height: 21px;
          margin-bottom: 0.1rem;
        }
        .input {
          text-align: left;
          font-size: 12px;
          line-height: 23px;
          font-family: Quicksand;
          font-style: normal;
          font-weight: 600;
          color: #1e1e1e;
          opacity: 1;
          border-color: #cccccc;
        }
        ::v-deep .required-error {
          position: relative;
        }
      }
      ::v-deep .form-group {
        p {
          display: flex;
          margin: 0px;
          color: #7d7d7d;
          font-size: 12px;
          font-family: Quicksand;
          padding: 0px 20px 0px 0px;
          display: flex;
          line-height: 20px;
          min-height: 21px;
          margin-bottom: 0.15rem;
        }
        .input {
          text-align: left;
          font-size: 12px;
          line-height: 23px;
          font-family: Quicksand;
          font-style: normal;
          font-weight: 600;
          color: #1e1e1e;
          opacity: 1;
          border-color: #cccccc;
        }
      }
      .form-control-disabled > .form-control {
        width: 100%;
        background-color: rgb(83, 94, 235, 0.1);
        p {
          display: flex;
          margin: 0px;
          color: #7d7d7d;
          font-size: 12px;
          font-family: Quicksand;
          padding: 0px 20px 0px 0px;
          display: flex;
          line-height: 20px;
          min-height: 21px;
          margin-bottom: 0.15rem;
        }

        .input {
          text-align: left;
          letter-spacing: 0.18px;
          color: #00218a !important;
          opacity: 0.9;
          font: normal normal bold 12px/23px Quicksand;
          font-weight: 600;
          width: 100%;
        }
      }

      .form-input-field {
        width: 75%;
      }
      .Searchable-select {
        width: 75%;
      }
      .add-evidence {
        width: 25%;
        margin-top: 1.75rem;
      }
    }
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .ae-details-section {
    .section-title {
      font-size: 24px;
    }
    .mandatory-note {
      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .ae-form {
      .column-view {
        width: 25%;
        .form-input-field {
          p {
            font-size: 14px;
            line-height: 20px;
          }
          .input {
            font-size: 18px;
            line-height: 23px;
          }
        }
        ::v-deep .form-group {
          p {
            font-size: 14px;
            margin-bottom: 0.45rem;
          }
          .input {
            font-size: 18px;
          }
        }
        .form-control-disabled > .form-control {
          width: 100%;
          background-color: rgb(83, 94, 235, 0.1);
          p {
            font-size: 14px;
            line-height: 20px;
          }

          .input {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }

      .column-view:nth-child(1),
      .column-view:nth-child(4),
      .column-view:nth-child(7) {
        width: 25%;

        .form-input-field {
          width: 70%;
        }
        .add-evidence {
          width: 30%;
        }
      }

      .column-view:nth-child(3),
      .column-view:nth-child(6),
      .column-view:nth-child(9) {
        width: 25%;

        .form-input-field {
          width: 60%;
        }
        .add-evidence {
          width: 30%;
        }
      }

      .column-view:nth-child(2),
      .column-view:nth-child(5),
      .column-view:nth-child(8) {
        width: 41%;

        .form-input-field {
          width: 75%;
        }
        .add-evidence {
          width: 25%;
        }
      }
    }
  }
}
@media (min-width: 1700px) {
  .ae-details-section {
    .section-title {
      font-size: 26px;
    }
    .mandatory-note {
      p {
        font-size: 14px;
        line-height: 14px;
      }
    }
    .ae-form {
      .column-view {
        width: 25%;
        .form-input-field {
          p {
            font-size: 16px;
            line-height: 20px;
          }
          .input {
            font-size: 16px;
            line-height: 23px;
          }
        }
        ::v-deep .form-group {
          p {
            font-size: 16px;
            margin-bottom: 0.5rem;
          }
          .input {
            font-size: 18px;
          }
        }
        .form-control-disabled > .form-control {
          width: 100%;
          background-color: rgb(83, 94, 235, 0.1);
          p {
            font-size: 16px;
            line-height: 20px;
          }

          .input {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }

      .column-view:nth-child(1),
      .column-view:nth-child(4),
      .column-view:nth-child(7) {
        width: 25%;

        .form-input-field {
          width: 69%;
        }
        .add-evidence {
          width: 31%;
        }
      }

      .column-view:nth-child(3),
      .column-view:nth-child(6),
      .column-view:nth-child(9) {
        width: 30%;

        .form-input-field {
          width: 60%;
        }
        .add-evidence {
          width: 30%;
        }
      }

      .column-view:nth-child(2),
      .column-view:nth-child(5),
      .column-view:nth-child(8) {
        width: 35%;

        .form-input-field {
          width: 75%;
        }
        .add-evidence {
          width: 25%;
        }
      }
    }
  }
}

@media (max-width: 1050px) {
  .ae-details-section {
    .ae-form {
      .column-view {
        width: 45%;
        padding-right: 1rem;
      }
    }
  }
}

@media (max-width: 922px) {
  .ae-details-section {
    .ae-form {
      .column-view {
        width: 100%;
      }
    }
  }
}
</style>
