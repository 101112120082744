import { render, staticRenderFns } from "./AddressFormComponent.vue?vue&type=template&id=4f09e4d0&scoped=true&"
import script from "./AddressFormComponent.vue?vue&type=script&lang=js&"
export * from "./AddressFormComponent.vue?vue&type=script&lang=js&"
import style0 from "./AddressFormComponent.vue?vue&type=style&index=0&id=4f09e4d0&prod&lang=scss&scoped=true&"
import style1 from "./AddressFormComponent.vue?vue&type=style&index=1&id=4f09e4d0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f09e4d0",
  null
  
)

export default component.exports