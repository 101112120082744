<template>
  <div>
    <k-dialog
      v-if="visibleDialog"
      :title="'Please confirm'"
      @close="closeDialog"
      :width="1000"
    >
      <div class="dialog-content-container">
        <!-- Row 1 -->
        <div class="dialog-row">
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.companyName"
                :disabled="
                  parsedAddressDetails?.companyName?.isDisabled ?? true
                "
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">C/O or Company Name</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.companyName?.value || ''
                }}</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.department"
                :disabled="parsedAddressDetails?.department?.isDisabled ?? true"
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Department</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.department?.value || ''
                }}</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.subDepartment"
                :disabled="
                  parsedAddressDetails?.subDepartment?.isDisabled ?? true
                "
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Sub Department</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.subDepartment?.value || ''
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Row 2 -->
        <div class="dialog-row">
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.streetName"
                :disabled="parsedAddressDetails?.streetName?.isDisabled ?? true"
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Street Name</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.streetName?.value || ''
                }}</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.buildingNumber"
                :disabled="
                  parsedAddressDetails?.buildingNumber?.isDisabled ?? true
                "
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Building Number</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.buildingNumber?.value || ''
                }}</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.buildingName"
                :disabled="
                  parsedAddressDetails?.buildingName?.isDisabled ?? true
                "
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Building Name</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.buildingName?.value || ''
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Row 3 -->
        <div class="dialog-row">
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.floor"
                :disabled="parsedAddressDetails?.floor.isDisabled ?? true"
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Floor</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.floor?.value || ''
                }}</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.postBox"
                :disabled="parsedAddressDetails?.postBox.isDisabled ?? true"
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Postbox</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.postBox?.value || ''
                }}</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.room"
                :disabled="parsedAddressDetails?.room?.isDisabled ?? true"
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Room</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.room?.value || ''
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Row 4 -->
        <div class="dialog-row">
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.postCode"
                :disabled="parsedAddressDetails?.postCode?.isDisabled ?? true"
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Postcode</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.postCode?.value || ''
                }}</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.country"
                :disabled="parsedAddressDetails?.country?.isDisabled ?? true"
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Country</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.country?.value?.text || ''
                }}</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.subDivision"
                :disabled="
                  parsedAddressDetails?.subDivision?.isDisabled ?? true
                "
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Country Subdivision</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.subDivision?.value?.text || ''
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Row 5 -->
        <div class="dialog-row">
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.town"
                :disabled="parsedAddressDetails?.town?.isDisabled ?? true"
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Town Name</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.town?.value || ''
                }}</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.townLocation"
                :disabled="
                  parsedAddressDetails?.townLocation?.isDisabled ?? true
                "
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">Town Location</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.townLocation?.value || ''
                }}</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="k-grid-check-container">
              <input
                type="checkbox"
                class="k-grid-check-input"
                v-model="formInputs.districtName"
                :disabled="
                  parsedAddressDetails?.districtName?.isDisabled ?? true
                "
              />
              <div class="label-value-container">
                <label class="k-grid-check-label">District Name</label>
                <span class="k-grid-check-value">{{
                  parsedAddressDetails?.districtName?.value || ''
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <dialog-actions-bar>
        <div class="button-container">
          <b-button class="action-button cancel-button" @click="closeDialog"
            >Cancel</b-button
          >
          <b-button
            :class="
              'action-button confirm-button' +
              (isSubmitButtonDisabled ? ' button-disabled' : '')
            "
            :theme-color="'primary'"
            @click="confirmParsedAddress"
            :disabled="isSubmitButtonDisabled"
            >Submit</b-button
          >
        </div>
      </dialog-actions-bar>
    </k-dialog>
  </div>
</template>
<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import { Button } from '@progress/kendo-vue-buttons'
import { Checkbox } from '@progress/kendo-vue-inputs'
import { parse } from 'path'

const getDefaultFormInputs = () => {
  return {
    companyName: false,
    department: false,
    subDepartment: false,
    streetName: false,
    buildingNumber: false,
    buildingName: false,
    floor: false,
    postBox: false,
    room: false,
    postCode: false,
    country: false,
    subDivision: false,
    town: false,
    townLocation: false,
    districtName: false
  }
}

export default {
  components: {
    'k-dialog': Dialog,
    'dialog-actions-bar': DialogActionsBar,
    kbutton: Button,
    Checkbox
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      required: true
    },
    parsedAddressDetails: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      visibleDialog: true,
      formInputs: getDefaultFormInputs(), // Initialize with default state
      confirmedAddress: {}
    }
  },
  computed: {
    isSubmitButtonDisabled() {
      return Object.values(this.formInputs).every((value) => !value)
    }
  },
  watch: {
    isVisible: {
      handler(val) {
        if (val) {
          // Reset form inputs when opening the dialog
          this.formInputs = getDefaultFormInputs()
        }
        this.visibleDialog = val
      },
      immediate: true
    }
  },
  methods: {
    confirmParsedAddress() {
      for (const field in this.formInputs) {
        if (this.formInputs[field]) {
          this.confirmedAddress[field] = this.parsedAddressDetails[field].value
        }
      }
      this.$emit('confirmParsedAddress', this.confirmedAddress)
      this.formInputs = getDefaultFormInputs()
      this.closeDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 350px;
  overflow-y: scroll;

  .dialog-row {
    // Need to add 4 columns of equal width
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    margin: 0;
    width: 100%;
    margin-bottom: 0.5rem;
    .column {
      width: 100%;
      .k-grid-check-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .label-value-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          //   gap: 5px;
          .k-grid-check-label {
            font-family: Quicksand;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            color: gray;
          }
          .k-grid-check-value {
            font-family: Quicksand;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
          }
        }

        .k-grid-check-input {
          margin-right: 10px;
          margin-bottom: 5px;
          width: 1.5rem;
          height: 1.5rem;
        }

        .k-grid-check-input:disabled {
          background-color: #f0f0f0; /* Lighter background */
          border-color: #dcdcdc; /* Lighter border color */
          cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
          opacity: 0.5; /* Reduce opacity to indicate disabled */
        }
      }
    }
  }
}
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  height: 4rem;
  padding-bottom: 0.6rem;
  width: 100%;
  gap: 1rem;

  .action-button {
    color: #424242;
    font-size: 16px;
    font-weight: 600;
    min-width: 104px;
    height: 42px;
  }
  .confirm-button {
    background-color: #535eeb; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */
    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: left;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;
    margin-left: 1rem;
  }
  .confirm-button:hover {
    background-color: #00218a; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */

    opacity: 0.9;
  }

  .cancel-button {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */

    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    color: #444444;
    opacity: 1;
  }
  .cancel-button:hover {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */
    opacity: 0.9;
  }

  .button-disabled {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */

    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    color: #444444;
    opacity: 0.8;
    padding: 0;
  }

  .button-disabled:hover {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */
    opacity: 0.9;
  }
}
</style>
