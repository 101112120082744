<template>
  <Dialog v-if="visible" @close="handleClose" :width="'70%'" :height="'70%'">
    <div class="dialog-header">
      <h2 class="dialog-title">
        {{ title || 'Select Your Details' }}
      </h2>
    </div>
    <div v-if="exceptionHandling">
      <div class="toolbar-column">
        <b-button
          class="action-button export-csv-button"
          size="is-small"
          :disabled="isDownloadDataLoading"
          :loading="isDownloadDataLoading"
          @click="exportCSVAndDownload"
        >
          <b-icon icon="file" size="is-small" class="toolbar-icons"> </b-icon>
          &nbsp; Export CSV
        </b-button>
      </div>
      <div v-for="sgaId in multipleSgaIdList" :key="sgaId">
        <div class="subheading-container">
          <p v-html="multipleTitleObj[`${sgaId}`]"></p>
        </div>
        <div>
          <p :style="{ paddingTop: '12px' }">Hierarchy for Reference:</p>
          <Grid
            ref="grid-table"
            :style="{ height: '100%', minHeight: '150px', width: '100%' }"
            :data-items="exceptionGridData[`${sgaId}`]"
            :columns="exceptionGridColumns[`${sgaId}`]"
            :resizable="true"
            :reorderable="true"
            :expand-field="'expanded'"
            :cell-render="'highlightCellStyle'"
            :filterable="true"
            :filter="exceptionGridFilter[sgaId]"
            @filterchange="(ev) => exceptionFilterChange(ev, sgaId)"
          >
            <template v-slot:highlightCellStyle="{ props }">
              <td :class="[props.className, highlightClass(props)]">
                {{ props.dataItem[props.field] }}
              </td>
            </template>
          </Grid>
        </div>
        <br /><br />
      </div>
    </div>
    <div v-else>
      <div>
        <p v-if="description" v-html="description"></p>
        <br />
        Current Hierarchy:
        <Grid
          v-if="singleRecordGridData.length > 0"
          ref="grid-table"
          :style="{ height: '30%', minHeight: '110px', width: '100%' }"
          :data-items="singleRecordGridData"
          :resizable="true"
          :reorderable="true"
          :columns="singleRecordColumns"
          :expand-field="'expanded'"
        >
        </Grid>
      </div>

      <div>
        <p :style="{ paddingTop: '12px' }">Hierarchy for Reference:</p>
        <Grid
          ref="grid-table"
          :style="{ height: '100%', minHeight: '150px', width: '100%' }"
          :data-items="multiRecordGridData"
          :columns="multiRecordColumns"
          :resizable="true"
          :reorderable="true"
          :expand-field="'expanded'"
          :cell-render="'highlightCellStyle'"
          :filterable="true"
          :filter="filter"
          @filterchange="filterChange"
        >
          <template v-slot:highlightCellStyle="{ props }">
            <td :class="[props.className, highlightClass(props)]">
              {{ props.dataItem[props.field] }}
            </td>
          </template>
        </Grid>
      </div>
    </div>
    <!-- Dialog Action Buttons -->
    <DialogActionsBar>
      <div class="action-buttons">
        <b-button class="action-button cancel-button" @click="handleClose">
          Close
        </b-button>
      </div>
    </DialogActionsBar>
  </Dialog>
</template>

<script>
import { process } from '@progress/kendo-data-query'
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import { Grid, GridColumn } from '@progress/kendo-vue-grid'
import { Input } from '@progress/kendo-vue-inputs'
import Snackbar from '@/components/Snackbar'

export default {
  name: 'DMPSelectableMultipleGrid',
  components: {
    Dialog,
    DialogActionsBar,
    Grid,
    GridColumn,
    'k-input': Input
  },
  props: {
    description: {
      type: String
    },
    visible: {
      type: Boolean,
      default: false
    },
    multiRecordDataSource: {
      type: Array,
      default: () => []
    },
    singleRecordDataSource: {
      type: Array,
      default: () => []
    },
    title: String,
    closeDialog: Function,
    confirm: Function,
    multipleTableObj: {
      type: Object,
      default: () => {}
    },
    multipleTitleObj: Object,
    multipleSgaIdList: Array,
    exceptionHandling: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      singleRecordGridData: this.singleRecordDataSource,
      multiRecordGridData: this.multiRecordDataSource,

      isDownloadDataLoading: false,
      exceptionGridData: this.multipleTableObj,
      filter: {},
      exceptionGridFilter: {}
    }
  },
  computed: {
    multiRecordColumns() {
      let columns = []
      if (this.multiRecordDataSource?.length > 0) {
        columns.push(
          ...Object.keys(this.multiRecordDataSource[0]).map((key) => {
            const colData = {
              field: key,
              title: key
            }
            return colData
          })
        )
      }
      return columns
    },
    singleRecordColumns() {
      let columns = []
      if (this.singleRecordDataSource?.length > 0) {
        columns.push(
          ...Object.keys(this.singleRecordDataSource[0]).map((key) => {
            const colData = {
              field: key,
              title: key
            }
            return colData
          })
        )
      }
      return columns
    },
    exceptionGridColumns() {
      const obj = {}
      for (let sgaId in this.multipleTableObj) {
        let columns = []
        if (this.multipleTableObj[sgaId]?.length > 0) {
          columns.push(
            ...Object.keys(this.multipleTableObj[sgaId][0]).map((key) => {
              const colData = {
                field: key,
                title: key
              }
              return colData
            })
          )
        }
        obj[sgaId] = columns
      }
      return obj
    }
  },
  watch: {
    singleRecordDataSource(newDataItems) {
      this.singleRecordGridData = newDataItems
    },
    multiRecordDataSource(newDataItems) {
      this.multiRecordGridData = newDataItems
    },
    multipleTableObj(newDataItems) {
      this.exceptionGridData = newDataItems
    }
  },
  methods: {
    handleClose() {
      this.closeDialog()
    },
    handleConfirm() {
      this.handleClose()
    },
    highlightClass(props) {
      if (!this.highlight) return ''

      const {
        fields: hFields,
        values: hValues,
        columns,
        exclude
      } = this.highlight
      const cellValue = props.dataItem[props.field]

      if (exclude && exclude.includes(cellValue)) {
        return ''
      }

      if (
        (columns && columns.includes(props.field)) ||
        (hFields &&
          hValues &&
          hFields.includes(props.field) &&
          hValues.includes(cellValue))
      ) {
        return 'highlight'
      }
    },

    async exportCSVAndDownload() {
      if (this.multipleTableObj == null) {
        Snackbar({ message: 'No Data to export.', type: 'is-danger' })
        return
      }
      this.isDownloadDataLoading = true
      Snackbar({
        message:
          'Export in progress. This may take a few minutes. The file will be downloaded in the background.',
        type: 'is-info'
      })
      let validationDetails = []
      for (const table in this.multipleTableObj) {
        validationDetails = [
          ...validationDetails,
          ...this.multipleTableObj[table]
        ]
        // this.downloadCSV(this.multipleTableObj[table], `${table}.csv`)
      }
      this.downloadCSV(validationDetails, `${this.title}.csv`)
      this.isDownloadDataLoading = false
    },

    downloadCSV(jsonData, filename = 'validation_data.csv') {
      if (!jsonData || jsonData.length === 0) {
        return
      }

      const headers = Object.keys(jsonData[0])

      const csvRows = []
      csvRows.push(headers.join(','))

      jsonData.forEach((row) => {
        const values = headers.map((header) =>
          row[header] !== undefined
            ? `"${String(row[header]).replace(/"/g, '""')}"`
            : ''
        )
        csvRows.push(values.join(','))
      })

      const csvString = csvRows.join('\n')

      // create a Blob and download link
      const blob = new Blob([csvString], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()

      // clean up
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    },

    processExceptionGridData(data, sgaId) {
      return process(data, {
        filter: this.exceptionGridFilter[sgaId]
      })
    },
    updateExceptionGridData(gridSgaId) {
      const processedData = this.processExceptionGridData(
        this.multipleTableObj[gridSgaId],
        gridSgaId
      )
      this.exceptionGridData = {
        ...this.exceptionGridData,
        [gridSgaId]: processedData || []
      }
    },
    exceptionFilterChange: function (ev, sgaId) {
      this.exceptionGridFilter = {
        ...this.exceptionGridFilter,
        [sgaId]: ev.filter
      }
      this.updateExceptionGridData(sgaId)
    },
    processGridData(data) {
      return process(data, {
        filter: this.filter
      })
    },
    updateGridData() {
      const processedData = this.processGridData(this.multiRecordDataSource)
      this.multiRecordGridData = processedData || []
    },
    filterChange: function (ev) {
      this.filter = ev.filter
      this.updateGridData()
    },
    destroyed() {
      this.filter = null
      this.exceptionGridFilter = null
      this.updateGridData()
    }
  }
}
</script>

<style scoped lang="scss">
$primary-color: #3f6ad8;

.local-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  .inava-loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
}

.dialog-header {
  padding: 10px;
  border-radius: 5px 5px 0 0;
  text-align: left;
  .dialog-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    color: $primary-color;
  }
}

.k-input {
  border: 1px solid #cccccc;

  input {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    font-family: Quicksand;
    font-style: normal;
  }

  .k-input-inner {
    margin-left: 0.1rem;
  }

  .k-input-prefix {
    margin-left: 0.5rem;
    color: #cccccc;
  }
}
.k-textbox {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 0.5rem;

  &:focus {
    border-color: $primary-color;
    outline: none;
  }
}

::v-deep .k-table-td {
  // Removed filter icon on grid
  .k-filtercell {
    .k-filtercell-wrapper {
      .k-filtercell-operator {
        display: none !important;
      }
    }
  }
}

.highlight {
  color: #ff0000;
}

.radio-column {
  text-align: center;
  .radio-button {
    height: 17px;
    width: 17px;
  }
}
.k-dialog-wrapper {
  z-index: 999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  .action-button {
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.18px;
    border-radius: 8px;
    padding: 8px;
  }
  .cancel-button {
    background-color: #cccccc;
    opacity: 1;
    color: #444444;
    &:hover {
      background-color: #cccccc;
      opacity: 0.9;
    }
  }
  .confirm-button {
    background-color: #535eeb;
    color: #ffffff;
    opacity: 1;
    margin-left: 1rem;
    &:hover {
      background-color: #00218a;
      opacity: 0.9;
    }
  }
}
.grid-link {
  color: #007bff; /* Custom hyperlink color */
  text-decoration: none; /* Removes underline */
}

.grid-link:hover {
  color: #0056b3; /* Darker color on hover */
  text-decoration: underline; /* Adds underline on hover */
}

.view-icon {
  cursor: pointer;
  color: #535eeb;
}

.subheading-container {
  display: flex;
  justify-content: space-between;
}
.toolbar-column {
  display: flex;
  align-items: center;
  justify-content: right;
  .export-csv-button {
    background-color: #cccccc;
    border: 1px solid black;
    font-weight: bold;
    border-radius: 5px;
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}
</style>
